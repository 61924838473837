import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FloatingLabel, Button, Card } from "react-bootstrap";
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import 'pages/Store/Store.scss';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import url from 'services/urls.json';
import { getMethod, getMethodWithToken, postMethodWithToken } from "services/apiServices";
import Pagination from "react-js-pagination";
import { htmlToText } from 'html-to-text';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { commonCallApi } from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";
import cartCallApi from "store/cart/action";
import { CART } from "store/cart/constant";
import userCallApi from "store/user/action";
import { USER } from "store/user/constant";
import { toast } from "react-toastify"
import { ClearTokenRedirectLogin } from "context/context";
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import styled from "styled-components";
import PlaceholderInput from "components/Inputs/PlaceholderInput/PlaceholderInput";
import productDetailsCallApi from "store/productDetails/action";
import { PRODUCT_DETAILS } from "store/productDetails/constant";
import { CATEGORY_DROPDOWN } from "store/categoryDropdown/constant";
import categoryDropdownCallApi from "store/categoryDropdown/action";
import dropdownMasterCallApi from "store/dropdownMaster/action";
import { DROPDOWN_MASTER } from "store/dropdownMaster/constant";


const WrapperImage = styled.div`
background: url(${props => props.image});
height: 346px;
max-width:346px;
margin:auto;
marginTop: 20px;
background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`

const itemsPerPageOptions = [
    { value: 10, label: '10' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 5, label: '5' },
];


function HtmlToPlainText({ htmlContent }) {
    const plainText = htmlToText(htmlContent, {
        wordwrap: 130
    });

    return <div>{plainText}</div>;
}

const Store = () => {
    const navigate = useNavigate()
    const [storeList, setStoreList] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [count, setCount] = useState(1);
    const [sortPrice, setSortPrice] = useState([]);
    const [category, setCategory] = useState([]);
    const [categoryValue, setCategoryValue] = useState('')
    const [price, setPrice] = useState('')
    const cartSelector = useSelector(state => state.commonReducer?.cart?.cart)
    const [totalQuantity, setTotalQuantity] = useState(cartSelector ?? 0);

    const categoryDropdown = useSelector(state => state?.categoryDropdownReducer?.payload?.data)
    const dropdownMasterColor = useSelector(state => state?.dropdownMasterReducer?.payload?.data)

    var [activePage, setPage] = useState(sessionStorage.getItem("currentPage") !== null ? parseInt(sessionStorage.getItem("currentPage")) : 1);


    useEffect(() => {
        handleSearchList();
    }, [itemsPerPage, price]);

    const handleItemsPerPageChange = (e) => {
        setPage(Number(e.target.value));
        setItemsPerPage(e.target.value)
    };

    const handleSortPrice = async () => {
        await getMethod(url.sortPrice).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setSortPrice(response.data)
            }
        })
    }

    const handleCategory = async () => {
        let apiUrl = `${url.categoryDropdown}?parent_slug=accessories`
        await getMethod(apiUrl).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setCategory(response.data)
            }
        })
    }
    const handlecategoryDropdwon = (e) => {
        setCategoryValue(e.target.value)
    }
    useEffect(() => {
        handleSortPrice();
        handleCategory();
        getDropdownValues();
    }, [])
    const handleSearchList = async () => {
        setPage(1);
        let apiUrl = `${url.buy}?catagory=accessories&paginate=true&page_limit=${itemsPerPage}&sort${price}`;
        let response;
        response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if (response.status === 200 || response.status === 201) {
            setStoreList(response.data.results);
            setCount(response.data ? response.data.count : "");
        }
        else if (response.status === 401 || response.status === 403) {
            ClearTokenRedirectLogin(navigate)
        }
    }
    const handlePageChange = async (pageNumber) => {
        let apiUrl = `${url.buy}?catagory=accessories&page=${pageNumber}&paginate=true&page_limit=${itemsPerPage}&sort=${price}`;
        setPage(pageNumber);
        sessionStorage.setItem("currentPage", pageNumber);
        let response;
        response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        setStoreList(response.data && response.data.results);

    };
    console.log("asd", storeList)
    const handlePrice = (e) => {
        setPrice(e.target.value)
    }
    const dispatch = useDispatch()
    const handleAddCart = async (product) => {

        let body = {
            "url": product,
            "quantity": 1
        }

        await postMethodWithToken(url.addCart, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                const basket = response.data.lines.split('/api/v1/')[1]
                toast.success("Item Added to cart")
                dispatch(userCallApi(USER, {}, {
                    run: true,
                    handleFunction: (res) => handleCartCount(res?.data?.basket_id)
                }))

                localStorage.setItem('cartAPI', basket)
                localStorage.setItem("clearAllCart", response.data.url)
            }
            else if (response.status === 401){
                navigate('/signin')
            }
            else if (response.status === 406) {
                toast.error("Out Of Stock")
            }
        })

    }


    const handleCartCount = (basketId) => {
        dispatch(cartCallApi(CART, {}, {
            url: `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleSuccess(res)
        }))
    };

    const handleSuccess = (res) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity }, { run: true }))
    };
    // const handleCart = async () => {
    //     await getMethodWithToken(localStorage.getItem('cartAPI')).then((response) => {
    //         if (response.status === 200 || response.status === 201) {

    //             let count = 0;

    //             response.data?.length > 0 && response.data.map((data) => (
    //                 count = count + data?.quantity
    //             ))

    //             setTotalQuantity(count);
    //         }
    //     })
    // }

    // useEffect(() => {
    //     handleCart()
    // }, [])
    // price_low_to_high
    // 10
    const [formValue, setFormvalue] = useState({
        sortByPrice: '',
        type: '',
        colors: ''
    });
    
    const [filterList, setFilterList] = useState([]);
    
    // Handle form changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormvalue((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    
    // Fetch item list whenever formValue changes
    useEffect(() => {
        getItemList(formValue);
    }, [formValue]);
    
    const getItemList = async (value) => {
        let apiUrl = `${url.buy}?sort=${value.sortByPrice}&catagory=accessories&paginate=true&page_limit=20&child=${value.type}&colour=${value.colors}`;
        
        let response;
        try {
            response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl);
            setFilterList(response?.data || []);
        } catch (error) {
            console.error("Error fetching items:", error);
        }
    };
    
    console.log("object", filterList)
    // const getProductDetails =(id, type, price, color)=>{
    //     // let apiUrl = `${url.buy}?catagory=accessories&page=${pageNumber}&paginate=true&page_limit=${itemsPerPage}&sort=${price}`;
    //     dispatch(productDetailsCallApi(PRODUCT_DETAILS, { id: id, type:type, price:price, color:color  }, {
    //         run: true,
    //         handleFunction: () => {},
    //         toast: true,
    //         handleToast: (error) => { toast.error(error?.data?.detail)}
    //     }));
    // }
    const getDropdownValues = () => {
        dispatch(categoryDropdownCallApi(CATEGORY_DROPDOWN, { id: "accessories" }, {
            run: true,
            handleFunction: () => { },
            toast: true,
            handleToast: (error) => { toast.error(error?.data?.detail) }
        }));
        dispatch(dropdownMasterCallApi(DROPDOWN_MASTER, { id: "colour" }, {
            run: true,
            handleFunction: () => { },
            toast: true,
            handleToast: (error) => { toast.error(error?.data?.detail) }
        }));
    }
    console.log("qwe", dropdownMasterColor)
    return (
        <div style={{ fontFamily: "Manrope, sans-serif", background: "#fafafa", paddingBottom: '96px' }} className="store">
            <Container >
                <Row className="align-items-center" style={{ paddingTop: '58px' }}>
                    <Col md={4}>
                        <H1text size='36' lineHeight={'36'} fontWeight='600' className="text-black " marginTop={'0'}>Store</H1text>
                    </Col>
                    <Col md={8}>
                        <div className="form_box  mt-2 mt-md-0">
                            {/* d-flex justify-content-start justify-content-md-end mt-2 mt-md-0 gap-md-2 */}
                            <Row className="justify-content-end">

                                <Col className="inputSize" sm={12} md={4} style={{ padding: '0px 10px' }} >
                                    {/* <FloatingLabel
                                        controlId="floatingSelectCategory"
                                        label={<span style={{ color: 'black', backgroundColor:'transparent !important' }}>Categories</span>}  // Set label color to black
                                        className="label labelWidth "
                                        style={{ fontSize: '12px' }}
                                        onChange={handlecategoryDropdwon}
                                    >
                                        <Form.Select aria-label="Floating label select example" className="backgroundForm backgroundFormExtra">
                                            {category && category.length > 0 && category.map((data, index) => (
                                                <option key={index} value={data?.slug}>{data?.name}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel> */}
                                    <PlaceholderInput
                                        label={'Type'}
                                        placeholder={'Type'}
                                        data={categoryDropdown}
                                        value={formValue?.type}
                                        name={'type'}
                                        onChange={(e) => handleChange(e)}
                                    />

                                </Col>
                                {/* <Col sm={12} md={4} className="mt-2 mt-md-0">
                                    <FloatingLabel controlId="floatingSelectSort" label={<span style={{ color: 'black' }}>Sort By Price</span>} className="label labelWidth">
                                        <Form.Select aria-label="Floating label select example" className="backgroundForm backgroundFormExtra" onChange={handlePrice}>
                                            {sortPrice && sortPrice.length > 0 && sortPrice.map((data, index) => (
                                                <option value={data?.slug}>{data?.name}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col> */}
                                <Col sm={12} md={4} className="mt-2 mt-md-0 inputSize" style={{ padding: '0px 10px' }}>
                                    <PlaceholderInput
                                        label={'Price, $'}
                                        placeholder={'Price, $'}
                                        data={sortPrice}
                                        value={formValue?.sortByPrice}
                                        name={'sortByPrice'}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </Col>
                                <Col sm={12} md={4} className="mt-2 mt-md-0 inputSize" style={{ padding: '0px 10px' }}>
                                    {/* <FloatingLabel controlId="floatingSelectPerPage" label={<span style={{ color: 'black' }}>Per Page</span>} className="label pageFilter">
                                        <Form.Select aria-label="Floating label select example" className="backgroundForm backgroundFormExtra" onChange={handleItemsPerPageChange}>
                                            {itemsPerPageOptions.map((option, index) => (
                                                <option key={index} value={option.value}>{option.label}</option>
                                            ))}
                                        </Form.Select>
                                    </FloatingLabel> */}
                                    <PlaceholderInput
                                        label={'Colors'}
                                        placeholder={'Colors'}
                                        data={dropdownMasterColor}
                                        value={formValue?.colors}
                                        name={'colors'}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row className="" style={{ marginTop: '42px' }}>
                    {filterList?.results?.length > 0 && filterList?.results.map((item, index) => (
                        <Col sm={12} md={6} lg={6} xl={3} xxl={3} key={index} className="storeProducts">
                            <Card className="cardProduct" style={{ background: '#ffffff', padding: '8px', border: 'none', borderRadius: '24px' }}>
                                <div className="" style={{ borderRadius: '12px', background: '#fafafa', height: '250px', padding: '25px', display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                                    {/* ,padding:'25px' */}
                                    <Card.Img style={{ borderRadius: '12px', background: '#fafafa' }} variant="top" src={item?.images[0]?.original} />
                                    {/* <WrapperImage image={item?.images[0]?.original}/> */}
                                </div>
                                <Card.Body className="p-0" style={{ marginTop: '8px' }}>
                                    <div style={{ background: '#fafafa', border: 'none', borderRadius: '12px', marginTop: '8px', padding: '12px 16px' }}>
                                        <Card.Title style={{ fontSize: '16px', fontWeight: '600' }} className="title">{item?.title?.trim().split(/\s+/).slice(0, 2).join(' ')}</Card.Title>
                                        <Card.Text  >
                                            <div className="description">
                                                <HtmlToPlainText htmlContent={item?.description} />
                                            </div>

                                            <div className="pt-2" style={{ fontWeight: '700', fontSize: '16px' }}>
                                                <p>${item?.price_incl_tax}
                                                    {/* <span className="text-danger" style={{ textDecoration: 'line-through' }}> ${item?.price_excl_tax}</span> */}
                                                </p>
                                            </div>
                                        </Card.Text>
                                        <Button variant="outline-dark" className="rounded-3 p-2 fw-bold CartButton w-100" onClick={() => handleAddCart(item?.url)}>Add to Cart</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>




                <div className="" style={{ marginTop: '76px' }}>
                    {count > 1 ? (
                        <Pagination
                            prevPageText={
                                filterList?.previous ? <Button variant="outline-dark" className="w-35 fw-bold CartButton pe-4 ps-4 pt-2 pb-2" disabled={count < itemsPerPage}>
                                    <KeyboardDoubleArrowLeftIcon />
                                    <span className="ms-2">Previous</span>
                                </Button> : ""
                            }
                            nextPageText={
                                filterList?.next ? <Button variant="outline-dark" className="w-35 fw-bold CartButton pe-4 ps-4 pt-2 pb-2" disabled={count < itemsPerPage}>
                                    <span className="ms-2">Next</span>
                                    <KeyboardDoubleArrowRightIcon />
                                </Button> : ""
                            }
                            activePage={activePage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={count}
                            onChange={handlePageChange}
                            marginPagesDisplayed={0}
                            hideFirstLastPages={true}
                            pageRangeDisplayed={0}
                            itemClass="page-item"

                        />
                    ) : null}
                </div>

            </Container>
            {/* <div style={{ marginTop: '96px' }}>
                <PerfectScooter />
            </div> */}
        </div>
    );
};

export default Store;
