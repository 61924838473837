import React, { useContext } from 'react';
import styled from 'styled-components';
import { string } from "prop-types";

const Wrapper = styled.p`
	margin-bottom: ${(props) => (props.marginBottom + "px")};
	font-size: ${(props) => (props.size + "px")};
	color:  ${(props) => (props.color)};
	margin-top: ${(props) => (props.marginTop + "px")};
	margin-left:${(props) => (props.marginLeft + "px")};
	font-family: ${(props) => (props.fontFamily)};
	font-weight: ${(props) => (props.fontWeight)};
	line-height: ${(props) => (props.lineHeight + "px")};
	text-decoration:${(props) => (props.textDecoration)};
	cursor:${(props) => (props.cursor)};
	${(props) =>
		props.textTransform && `
 	    text-transform:  ${props.textTransform};
    `}

    ${(props) =>
		props.TextAlign && `
 	    text-align:  ${props.TextAlign};
    `}

    ${(props) =>
		props.padding && `
 	    padding:  ${props.padding};
    `}

    ${(props) =>
		props.borderColor && `
		border: 1px solid;
 	    border-color:  ${props.theme[props.borderColor]};
    `}

    ${(props) =>
		props.borderWidth && `
 	    border-width:  ${props.borderWidth};
    `}
`;

function Ptext(props) {
	return (
		<Wrapper {...props} >
			{props.children}
		</Wrapper>
	);
}

Ptext.propTypes = {
	marginTop: string.isRequired,
	size: string.isRequired,
	subSize: string,
	subMarginTop: string,
	fontWeight: string,
	fontFamily: string
};

Ptext.defaultProps = {
	marginTop: 10,
	size: 24,
	fontWeight: 600,
	fontFamily: 'Manrope',
	marginBottom: 0,
	lineHeight: 24,
};

export default Ptext;