import { React, useState, useEffect } from "react";
import Questions from 'pages/Home/Questions/Questions';
import { Col, Container, Row, Button } from 'react-bootstrap';
import 'pages/Metro/Metro.scss';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import FeatureMetro from './featureMetro/featureMetro';
import CustomCarousel from "components/CustomCarousel/CustomCarousel";
import ImageTextCard from "components/Cards/ImageTextCard/ImageTextCard";
import H1text from "components/H1text/H1text";
import { Box } from "@mui/material";
import heroImage from 'assets/images/heroImageMetro.png'
import Ptext from "components/Ptext/Ptext";

const Metro = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <div style={{ fontFamily: "Manrope, sans-serif" }} >
            {/* <div className="metro relative">
                <Container className="pb-5">
                    <Row>
                        <Col md={6} className="pb-5">
                            <div className="metroContent ">
                                <div className="text-black absolute heading">
                                    <h1 className="metroHeading" style={{ fontWeight: '600' }}>Metro+</h1>
                                    <p className="paraImage" style={{ fontWeight: '500' }}>Unlock the ultimate e-scooter experience with our monthly subscription, offering seamless integration.
                                        Effortlessly discover local gems and coordinate ride-sharing with friends, because every journey is better when shared</p>
                                    <Button variant="dark" className="mt-4 rounded-5">Get started <KeyboardArrowRightIcon /></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}


            <div>
                {/* <FeatureMetro /> */}
            </div>
            {/* <Container > */}


            <Box className="hiddenDiv" style={{ position: 'relative', visibility: 'hidden', top: '-112px'}}>
                <img src={heroImage} alt="" />
            </Box>
            {/* style={{ position: 'absolute', top: 0 }} */}
            {/* style={{ position: 'absolute', top: 0 }}  */}
            <Box className="metro d-flex align-items-center" style={{ position: 'absolute', top: 0 }} >
                {/* <img src={heroImage} alt="" /> */}
                <Container >
                    <Box className='mt-0 mt-lg-5' style={{ display: 'flex', alignItems: 'center', maxWidth:'394px' }}>
                        <Box>
                            <H1text size='54' lineHeight='54' fontWeight='600' marginTop='0' >Metro+</H1text>
                            <Ptext size='20' lineHeight='32' fontWeight='500' marginTop='36' >Unlock the ultimate e-scooter experience with our monthly subscription, offering seamless integration.
                                Effortlessly discover local gems and coordinate ride-sharing with friends, because every journey is better when shared</Ptext>
                            <Button variant="dark" className="rounded-5" style={{padding:'12px 12px 12px 24px', marginTop:'36px', display:'flex'}}>
                            <Ptext size='16' lineHeight='24' fontWeight='600' marginTop='0' >
                                Get started 
                            </Ptext>
                                <KeyboardArrowRightIcon /></Button>
                        </Box>
                    </Box>
                </Container>
            </Box>
            {/* style={{marginTop:'96px'}} */}
            <Box style={{ background: '#ffffff' }} >
                <Container >
                    <Box display={'flex'} justifyContent={'center'} className={'pt-4 pt-xl-0'}>
                        <H1text size='36' lineHeight='36' fontWeight='600' marginTop={0} >What will you get?</H1text>
                    </Box>
                    <Row className=' g-3' style={{ marginTop: "48px" }}>
                        <Col sm={12} md={3}>
                            <ImageTextCard
                                image={'https://metrorides.theclientdemos.com/assets/images/i1.png'}
                                height={'244px'}
                                heading={'Roadside assistance'}
                                subHeading={'Select and customize'}
                            />
                        </Col>
                        <Col sm={12} md={9}>
                            <ImageTextCard
                                image={'https://metrorides.theclientdemos.com/assets/images/i2.png'}
                                height={'244px'}
                                heading={'Creating routes'}
                                subHeading={'Select and customize your model for an unbeatable combination of power, agility, and style'}

                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <ImageTextCard
                                image={'https://metrorides.theclientdemos.com/assets/images/i3.png'}
                                height={'244px'}
                                heading={'Warranty'}
                                subHeading={'Select and customize'}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <ImageTextCard
                                image={'https://metrorides.theclientdemos.com/assets/images/i4.png'}
                                height={'244px'}
                                heading={'Delivery'}
                                subHeading={'Select and customize'}

                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <ImageTextCard
                                image={'https://metrorides.theclientdemos.com/assets/images/i5.png'}
                                height={'244px'}
                                heading={'Delivery'}
                                subHeading={'Select and customize'}

                            />
                        </Col>
                    </Row>
                    <Row className=' gy-lg-0 gy-3' style={{ marginTop: "48px" }}>

                    </Row>
                </Container>
            </Box>

            <div style={{ marginTop: '96px' }}>
                <div className="mt-5 mb-5 text-center">
                    <h1 style={{ fontWeight: '600', fontSize: '32px' }} >Feedback from subscribers</h1>
                </div>
                <div>
                    <Container >
                        <CustomCarousel
                            slidesToShow={3}
                            useFor={"Feedback"}
                        />
                    </Container>
                </div>
                <div style={{ marginTop: '96px' }}>
                    <Questions
                    />
                    <PerfectScooter
                        heading={'Your own perfect scooter'}
                        for={'Scooter'}
                        image={'https://metrorides.theclientdemos.com/assets/images/cta.png'}
                        top={'-96px'}
                        //   models={Models}
                        bgColor={'#fafafa'}
                    />
                </div>
            </div>
            {/* <div>
            </div> */}
            {/* </Container> */}
        </div>

    )
}

export default Metro