import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import 'pages/orderDetail/orderDetail.scss';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const OrderDetail = () => {

    return (
        <div className="orderDetail">
            <Container>
                <p className="orderDetailHeading">Order Details</p>
                <Row >
                    <Col sm={10} md={8}>
                        <div className="custom-font">
                            <p className="delivery">Delivered
                                <span className="deliveryDate ps-2">On 12 Aug 2024, Sunday</span>
                            </p>
                            <div className="d-md-flex gap-4">
                                <p className="order">Order placed on:
                                    <span className="orderDate ps-2">05 Aug 2024, Sunday</span>
                                </p>
                                <p className="order">Order ID:
                                    <span className="orderDate ps-2">4569874562123</span>
                                </p>
                            </div>

                        </div>
                    </Col>
                    <Col sm={10} lg={2} md={4} className="mb-2">
                        <Button  variant="outline-dark"  className="d-flex align-items-center gap-2 justify-content-center pt-2 pb-2 w-100">
                            <FileDownloadOutlinedIcon />
                            <p className="invoice mb-0">Invoice</p>

                        </Button>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
export default OrderDetail