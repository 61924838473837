import { React, useState, useEffect, lazy, Suspense } from "react";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Carousel from 'react-bootstrap/Carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PublicTransport from "./PublicTransport/PublicTransport";
import ModerApp from "./ModerApp/ModerApp";
import Reviews from "./Reviews/Reviews";
import "pages/Home/Home.scss";
import { getMethodWithToken, getMethod, postMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { useDispatch } from "react-redux";
import { ClearTokenRedirectLogin } from "context/context";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "routes/errorBoundary";
import { Box } from "@mui/material";
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import styled from "styled-components";
import CardImage2 from "assets/images/CardImage2.png";
import vehicle from "assets/images/vehicle.png";
import firstScreen from "assets/heroBanner/firstScreen.png";
import firstScreen2 from "assets/heroBanner/firstScreen2.png";
import firstScreen3 from "assets/heroBanner/firstScreen3.png";
import firstScreen4 from "assets/heroBanner/firstScreen4.png";
import userCallApi from "store/user/action";
import cartCallApi from "store/cart/action";
import { toast } from "react-toastify"
import { CART } from "store/cart/constant";
import { USER } from "store/user/constant";
import { commonCallApi } from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";

const WrapperDiv = styled.div`
.firstImage{
    background:url('https://metrorides.theclientdemos.com/assets/images/i6.png');
    height:504px;
    width:100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius:16px;

}
.thirdImage{
    background:url('https://metrorides.theclientdemos.com/assets/images/i7.png');
    height:244px;
    width:100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius:16px;
}
.secondImage{
    background-image: url(${props => props.image});
    height:244px;
    width:100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius:16px;
}
.textWidth{
    width:75%;
}
@media screen and (max-width:1200px){
    .textWidth{
        width:100%;
    }
}

`
const WrapperImageDiv = styled.div`
  background: url(${props => props.image});
  height: 346px;
  max-width:346px;
  margin:auto;
  marginTop: 20px;
  background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;
const Home = () => {
    const Questions = lazy(() => import("./Questions/Questions"));

    const array = new Array(3).fill(null);
    const navigate = useNavigate()
    const [selectedId, setSelectedId] = useState(4);

    const handleNavProducts = (id) => {
        setSelectedId(id);
    };

    const [activeIndex, setActiveIndex] = useState(0);
    const [id, setId] = useState('')
    const [order, setOrder] = useState(0)
    const [productList, setProductList] = useState([])
    const [productId, setProductId] = useState('')

    const handleSelect = (index) => {
        setActiveIndex(index);
    };
    useEffect(() => {
        handleProductList()
    }, [])

    const handleProductList = async () => {
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response = localStorage.getItem('token') ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        if (response.status === 200 || response.status === 201) {
            setProductList(response.data)
        }
        else if (response.status === 401 || response.status === 403) {
            ClearTokenRedirectLogin(navigate)
        }
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);


    const dispatch = useDispatch()
    const handleAddCart = async (product) => {

        let body = {
            "url": product,
            "quantity": 1
        }

        await postMethodWithToken(url.addCart, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                const basket = response.data.lines.split('/api/v1/')[1]
                toast.success("Item Added to cart")
                dispatch(userCallApi(USER, {}, {
                    run: true,
                    handleFunction: (res) => handleCartCount(res?.data?.basket_id)
                }))

                localStorage.setItem('cartAPI', basket)
                localStorage.setItem("clearAllCart", response.data.url)
            }
            else if (response.status === 401){
                navigate('/signin')
            }
            else if (response.status === 406) {
                toast.error("Out Of Stock")
            }
        })

    }


    const handleCartCount = (basketId) => {
        dispatch(cartCallApi(CART, {}, {
            url: `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleSuccess(res)
        }))
    };

    const handleSuccess = (res) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity }, { run: true }))
    };
    return (
        <WrapperDiv style={{ fontFamily: "Manrope, sans-serif" }} className="Home" image={CardImage2}>
            <Container className="containerMain p-0" fluid  >
                <div style={{ background: "#ffffff" }}>
                    <Carousel >
                        {/* {array.length > 0 && array.map((data, index) => ( */}
                        <Carousel.Item onClick={() => navigate('/scooters')} style={{cursor:'pointer'}}>
                            <img style={{ background: '' }} src={firstScreen} className="d-block w-100"   />
                            {/* <img src="https://metrorides.theclientdemos.com/assets/images/screen.svg" className="d-block w-100" /> */}

                        </Carousel.Item>
                        <Carousel.Item onClick={() => navigate('/scooters')} style={{cursor:'pointer'}}>
                            <img style={{ background: 'linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(67,67,68,1) 46%, rgba(203,203,203,1) 100%)' }} src={firstScreen2} className="d-block w-100" />
                            {/* <img src="https://metrorides.theclientdemos.com/assets/images/screen.svg" className="d-block w-100" /> */}

                        </Carousel.Item>
                        <Carousel.Item onClick={() => navigate('/scooters')} style={{cursor:'pointer'}}>
                            <img style={{ background: '' }} src={firstScreen3} className="d-block w-100" />
                            {/* <img src="https://metrorides.theclientdemos.com/assets/images/screen.svg" className="d-block w-100" /> */}

                        </Carousel.Item>
                        <Carousel.Item onClick={() => navigate('/scooters')} style={{cursor:'pointer'}}>
                            <img style={{ background: 'linear-gradient(360deg, rgba(75,86,99,1) 0%, rgba(133,137,140,1) 46%, rgba(80,91,96,1) 100%)' }} src={firstScreen4} className="d-block w-100" />
                            {/* <img src="https://metrorides.theclientdemos.com/assets/images/screen.svg" className="d-block w-100" /> */}

                        </Carousel.Item>
                        {/* ))} */}

                    </Carousel>
                </div>

                {/* Hero === */}
                {/* <Row > */}
                {/* <Col md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <img src={vehicle} alt="" />
                        </Box>
                        <Box style={{ transform: 'scaleX(-1)' }}>
                            <img src={vehicle} alt="" />
                        </Box>
                    </Col> */}
                {/* <Row >
                    <Col md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Box>
                            <img src={vehicle} alt="" />
                        </Box>
                        <Ptext>
                            Your Trip Time, Cut in Half 1
                        </Ptext>
                        <Ptext>
                            Your Trip Time, Cut in Half 0=====
                        </Ptext>
                    </Col>
                    <Col md={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <Ptext>
                            Your Trip Time, Cut in Half 2
                        </Ptext>
                        <Ptext>
                            Your Trip Time, Cut in Half 0=====
                        </Ptext>
                    </Col>
                    <Col md={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                    <Box style={{ transform: 'scaleX(-1)' }}>
                            <img src={vehicle} alt="" />
                        </Box>
                        <Ptext>
                            Your Trip Time, Cut in Half 3
                        </Ptext>
                        <Ptext>
                            Your Trip Time, Cut in Half 0=====
                        </Ptext>
                    </Col>
                </Row> */}
                {/* </Row> */}

                {/* Hero === */}

                <div className=" text-center">
                    <Box style={{ background: '#fafafa', padding: '96px 0' }}>
                        <Container >
                            <Row>
                                <Col md={12} className={'secondHeading'}>
                                    <H1text size={'36'} lineHeight={'36'} fontWeight={'600'}>What’s Your Model?</H1text>
                                </Col>
                                <Col md={12}>
                                    <div className=" d-flex flex-column justify-content-center">
                                        <Box className=" d-flex flex-column justify-content-center p-0 " gap={5} style={{backgroundColor: 'transparent' }}>
                                            <Box className="d-flex  justify-content-center flex-wrap" gap={2} style={{height:'fit-content !important'}} >
                                                {productList && productList.length > 0 && productList.map((data, index) => (
                                                    <Box href="#" className="text-dark">
                                                        <button className="d-flex align-items-center navButton" gap={5} style={{

                                                            padding: selectedId === data?.id ? '2px 24px 2px 2px' : '2px 24px 2px 2px', backgroundColor: selectedId === data.id ? '#4579F5' : '#f1f1f1',
                                                            border: 'none', borderRadius: '12px', height: '48px'

                                                        }} onClick={() => handleNavProducts(data?.id)}>
                                                            <div className="imgBox" style={{ borderRadius: '12px' }}>
                                                                <img src={data?.images[0]?.original} className="me-2" style={{ width: '44px', height: '44px', background: 'white', borderRadius: '12px' }} />
                                                            </div>
                                                            <span className={`title ${selectedId === data?.id ? 'selected' : ''}`} >
                                                                {data.title.trim().split(/\s+/).slice(0, 3).join(' ')}
                                                            </span>

                                                        </button></Box>
                                                ))}

                                            </Box>
                                        </Box>
                                        <Row style={{ marginTop: '48px', background: '#ffffff', borderRadius: '24px', padding: '16px 16px 16px 16px' }}>
                                            <Col lg={6} className="justify-content-center" style={{ background: '#fafafa', borderRadius: '12px', padding: '0px 0px' }}><div className="carouselBuy" >
                                                <Carousel activeIndex={activeIndex} onSelect={handleSelect} prevIcon={<ArrowBackIosIcon style={{ color: 'black' }} />}
                                                    nextIcon={<ArrowForwardIosIcon style={{ color: 'black' }} />} >
                                                    {productList && productList.length > 0 && productList.map((item, itemIndex) => (
                                                        item?.id === selectedId && item?.images.length > 0 && item.images.map((data, dataIndex) => (
                                                            <Carousel.Item key={`${itemIndex}-${dataIndex}`} className="text-center">
                                                                {/* <img src={data?.original} className="d-block mx-auto" alt={`Slide ${itemIndex}-${dataIndex}`} style={{ width: '361px', height: '361px' }} /> */}
                                                                <div style={{ marginTop: '20px' }}>
                                                                    <WrapperImageDiv image={data?.original} >

                                                                    </WrapperImageDiv>
                                                                </div>
                                                            </Carousel.Item>
                                                        ))
                                                    ))}
                                                </Carousel>

                                                <div className="d-flex justify-content-center mt-3">
                                                    {productList && productList.length > 0 && productList.map((data, index) => (
                                                        data?.id === selectedId && data?.images && data?.images.length > 0 && data?.images.map((image, index) => (
                                                            <img
                                                                key={index}
                                                                src={image?.original}
                                                                alt={`Indicator ${index}`}
                                                                className={`indicator-image ${activeIndex === index ? 'active' : ''}`}
                                                                onClick={() => handleSelect(index)}
                                                                style={{ cursor: 'pointer', width: '55px', height: '55px', objectFit: 'cover', margin: '0 5px', border: (activeIndex === index) ? '2px solid #4579F5' : 'none', borderRadius: '15%' }}
                                                            />
                                                        ))
                                                    ))}

                                                </div>
                                            </div></Col>
                                            {productList && productList.length > 0 && productList.map((data, index) => (
                                                data?.id === selectedId && (
                                                    <Col lg={6} ><div className="product_description" style={{ marginTop: '32px' }} >
                                                        <Stack direction="horizontal" className="align-items-center justify-content-between">
                                                            <div> <H1text size={'30'} lineHeight={'36'} fontWeight={'600'} marginTop={'0'} className="scooterName">{data?.title.trim().split(/\s+/).slice(0, 3).join(' ')}</H1text></div>
                                                            <div className="d-flex justify-content-between align-items-center gap-1 ">   <Button variant="dark" className=" rounded-5" style={{ padding: '2px 12px' }}> <Ptext size={'14'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'} >For the Urban Commuter</Ptext> </Button><span className="fw-bold ml-2" style={{ background: '#fafafa', borderRadius: '28px', padding: '4px 12px' }}>4/5</span></div>

                                                        </Stack>
                                                        <div>
                                                            <Row className="align-items-center text-start" style={{ marginTop: '32px' }} >
                                                                {/* <Col md={6} className="attributesLabel d-none d-md-block">
                                                                {data?.attributes && data?.attributes.length > 0 && data?.attributes.map((brand, index) => (
                                                                    <div className="d-flex align-items-start gap-2 ">
                                                                        <WatchLaterOutlinedIcon style={{ fontSize: '25px' }} />
                                                                        <p className="fw-bold">{brand?.name}</p>
                                                                    </div>
                                                                ))}
                                                            </Col> */}

                                                                {/* <div> */}
                                                                <Col xs={12} lg={12} style={{ padding: '20px', background: '#fafafa', borderRadius: '12px' }}>

                                                                    {/* sssss */}
                                                                    {data?.attributes && data?.attributes.length > 0 && data?.attributes.slice(0, 5).map((item, index) => (
                                                                            <div className="justify-content-between align-items-baseline d-flex" key={index}>
                                                                                <div className="d-flex align-items-center gap-2">
                                                                                    <img src={item?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5' }} alt="icon" />
                                                                                    <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>
                                                                                        {item?.name}
                                                                                    </Ptext>
                                                                                </div>
                                                                                <div gap={2} className="align-items-baseline pb-3 justify-content-end values d-sm-flex d-block">
                                                                                    <div className="mb-0 mx-2 brandValue">
                                                                                        <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>
                                                                                            {item?.value.trim().split(/\s+/).slice(0, 2).join('')}
                                                                                        </Ptext>
                                                                                    </div>
                                                                                    <div className="d-flex gap-2">
                                                                                        <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" alt="star" />
                                                                                        <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" alt="star" />
                                                                                        <img src="https://metrorides.theclientdemos.com/assets/images/blu.svg" alt="star" />
                                                                                        <img src="https://metrorides.theclientdemos.com/assets/images/gry.svg" alt="star" />
                                                                                        <img src="https://metrorides.theclientdemos.com/assets/images/gry.svg" alt="star" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    ))}


                                                                </Col>
                                                                {/* </div> */}

                                                            </Row>
                                                        </div>
                                                    </div>
                                                        <Stack direction="vertical" gap={3} className="productButtons w-100" style={{ marginTop: '32px', marginBottom: '32px' }}>
                                                            <Button variant="outline-dark" className=" w-100 fw-bold CartButton" style={{ borderRadius: '12px', padding: '11px' }}> <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'} onClick={() => handleAddCart(data?.url)}>Buy / ${data?.price_incl_tax}</Ptext></Button>
                                                            <Button variant="dark" className=" w-100 fw-bold rentButton" style={{ borderRadius: '12px', padding: '11px' }}><Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>Rent / ${data?.rent}</Ptext></Button>
                                                        </Stack>
                                                    </Col>)
                                            ))}

                                        </Row>
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </Box>
                    <div style={{ background: '#ffffff', paddingBottom: '96px' }}>
                        <Container  >
                            <Box style={{ paddingTop: '96px' }} >
                                <Box style={{ marginBottom: '48px' }}> <H1text size={'36'} lineHeight={'36'} fontWeight={'600'} marginTop={'0'} style={{ textAlign: 'left' }}>What do we offer?</H1text></Box>
                                <Row style={{ display: 'flex' }} className="g-5">
                                    <Col sm={6}>
                                        <Row className="g-3 text-left">
                                            <Col sm={12} className='firstImage' style={{ padding: '32px' }}>
                                                <H1text size={'24'} lineHeight={'32'} fontWeight={'600'} color={'#ffffff'}>
                                                    Warranty
                                                </H1text>
                                                <div className="textWidth">
                                                    <Ptext size={'14'} lineHeight={'24'} fontWeight={'400'} color={'#ffffff'}>
                                                        All rented scooters, and scooters purchased within 1 year are entitled to a full parts warranty. The replacement parts will be shipped and sent free of charge
                                                    </Ptext>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} className={'mt-3 mt-sm-5'} >
                                        <Row className="g-3 text-left">
                                            <Col sm={12} className='secondImage' style={{ padding: '32px', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                                                <H1text size={'24'} lineHeight={'32'} fontWeight={'600'} color={'#ffffff'}>
                                                    Delivery
                                                </H1text>
                                                <div className="textWidth">
                                                    <Ptext size={'14'} lineHeight={'24'} fontWeight={'400'} color={'#ffffff'}>
                                                        We’ll ship your new ride right to you!
                                                    </Ptext>
                                                </div>
                                            </Col>
                                            <Col sm={12} className='thirdImage' style={{ padding: '32px', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                                                <H1text size={'24'} lineHeight={'32'} fontWeight={'600'} color={'#ffffff'}>
                                                    Insurance
                                                </H1text>
                                                <div className="textWidth" style={{ maxWidth: '283px' }}>
                                                    <Ptext size={'14'} lineHeight={'24'} fontWeight={'400'} color={'#ffffff'}>
                                                        All scooters with a proper police report, proof of theft, within warranty are eligible for a replacement, you just pay off shipping
                                                    </Ptext>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Box>
                        </Container>
                    </div>
                    <div style={{ background: "#FAFAFA", padding: '0' }}>
                        <PublicTransport />
                        <ModerApp />
                    </div>
                    <div>
                        <Reviews />
                    </div>
                    <ErrorBoundary>
                        <Suspense fallback={<div>loading---</div>}>
                            <div>
                                <Questions />
                            </div>
                        </Suspense>
                    </ErrorBoundary>


                </div>
            </Container>
            <div >
            <PerfectScooter 
                 heading={'Your own perfect scooter'} 
                 for={'Scooter'}
                  image={'https://metrorides.theclientdemos.com/assets/images/cta.png'}
                  top={'-96px'}
                   />

            </div>
        </WrapperDiv>
    )
}

export default Home