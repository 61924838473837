import { React, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../SignIn/SignIn.scss';
import Button from 'react-bootstrap/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from 'react-router-dom';
import { postMethod } from 'services/apiServices';
import url from 'services/urls.json';
import { setToken } from 'services/common';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { signupCallApi } from 'store/SignUp/action';
import { SIGN_IN } from 'store/signIn/constant';
import signCallApi from 'store/signIn/action';
import Ptext from 'components/Ptext/Ptext';
import { Box } from '@mui/material';
import H1text from 'components/H1text/H1text';

const SignIn = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [validEmail, setValidEmail] = useState(true)

    const handleEmailChange = (e) => {

        setEmail(e.target.value)

    };
    const handlePasswordChange = (e) => {

        setPassword(e.target.value)
    };
    const getFormData = (e) => {
        let body = {
            username: email,
            password: password
        }
        e.preventDefault()
        dispatch(signCallApi(SIGN_IN, body, {
            run: true,
            handleFunction: (res) => handleSuccess(res),
            toast: true,
            handleToastError: (res) => handleFailure(res)
            // handleToastError:(res)=>toast.error(res?.data?.non_field_errors?.[0])
        }))

    }


    const handleSuccess = (res) => {
        localStorage.setItem("token", res.data.token)
        console.log('54')
        toast.success("Signin successfully !")
        navigate('/')
    }
    const handleFailure = (res) => {
        console.log(res?.data, "58")
        toast.error("Inalid Login")
        localStorage.removeItem('basektId')
        navigate('/signin')
    }
    useEffect(() => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (email && !emailRegex.test(email)) {
            setValidEmail(false);
        } else {
            setValidEmail(true);
        }
    }, [email]);

    return (
        <Box style={{ width: '100%', height: '62vh', minHeight: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box className='SignIn' style={{ width: '392px', padding: '16px' }}>
                {/* <Container className='mt-3 mb-5 pt-3 pb-5'> */}
                {/* <Row>
                    <Col md={4} className='mx-auto text-center'> */}
                <div style={{marginTop:'122px'}}>
                    <H1text size='36' lineHeight='36' fontWeight='600' style={{ marginBottom: '32px', textAlign: 'center' }}>Sign In</H1text>
                </div>
                <div className='form_box' style={{ marginTop: '32px' }} >
                    <form onSubmit={getFormData}>
                        <div style={{ marginBottom: '20px' }}>
                            <input type='email' placeholder='Email' name='email' autoFocus value={email} className="form-control" onChange={handleEmailChange} required />
                            {!validEmail && <p className='text-danger pt-2 text-start' style={{ fontSize: '12px' }}>Please enter a valid email address.</p>}
                        </div>
                        <div className=' relative' style={{ marginBottom: '20px' }}>
                            <input type={showPassword ? 'text' : 'password'} placeholder='Password' name='password' autoFocus value={password} className="form-control" onChange={handlePasswordChange} required />
                            <span className="passwordIcon cursor-pointer" onClick={() => setShowPassword(!showPassword)} >{showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</span>
                        </div>
                        <div style={{ marginBottom: '20px' }}>
                            <Ptext size='14' lineHeight='20' fontWeight='500' onClick={() => navigate("/forgotpassword")} className='text-left text-decoration-underline text-primary mb-3 cursor-pointer'>Forgot password?</Ptext>
                        </div>
                        <button type='submit' className='btn btn-dark btn-lg submit w-100' style={{ marginBottom: '136px' }} > <Ptext size='16' lineHeight='24' fontWeight='400' marginTop='0'>Sign In</Ptext> </button>
                        <div className='text-center'>
                            <Ptext size='14' lineHeight='20' fontWeight='500' marginTop='0' className='mb-0 fw-500' style={{ fontSize: '14px', marginBottom: '4px' }}> Still don’t have an account?  </Ptext>
                            <Ptext size='14' lineHeight='20' fontWeight='500' onClick={() => navigate("/signup")} marginTop='0' className='text-center fw-600 cursor-pointer' style={{ color: '#0d6efd', marginBottom:'122px' }} >Sign up</Ptext>
                        </div>

                    </form>
                </div>


                {/* </Col>
                </Row> */}
                {/* </Container> */}

            </Box>
        </Box>
    )
}

export default SignIn