import { takeLatest,put } from "redux-saga/effects";
import { getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { DROPDOWN_MASTER , DROPDOWN_MASTER_ERROR,DROPDOWN_MASTER_SUCCESS } from "./constant";
import { ClearTokenRedirectLogin } from "context/context";

function* dropdownMasterApi(action){
    try {
         // Destructure `id` from the action payload
         console.log("abc3",action)
         const { id } = action.data;

         // Construct the URL with the dynamic id
         const urlWithId = `${url.dropdownMaster}/${id}/`;
        const response=yield getMethodWithToken(urlWithId)
            if(response.status === 200 || response.status === 201){
                yield put({type:DROPDOWN_MASTER_SUCCESS , payload:response})
                if(action.external && action.external.run === true){
                    action.external.handleFunction(response)
                }
                if(action.external && action.external.toast === true){
                    action.external.handleToast(response)
                }
            }
            else {
                yield put({type:DROPDOWN_MASTER_ERROR , payload:response})

                if(response.status === 401 || response.status === 403){
                    ClearTokenRedirectLogin(action.external.navigate)
                  }

                if(action.external && action.external.toast === true){
                    action.external.handleToast(response)
                }
                
            }
    }
    catch(error){
        yield put({type:DROPDOWN_MASTER_ERROR , payload:error})
    }
}

function* dropdownMasterSaga(){
    yield takeLatest(DROPDOWN_MASTER , dropdownMasterApi)
}

export default dropdownMasterSaga