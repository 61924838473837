import { combineReducers } from "redux";
import signUpReducer from "./SignUp/reducer";
import countriesReducer from './Countries/reducer'
import commonReducer from './common/reducer'
import checkoutReducer from "./checkout/reducer";
import userReducer from "./user/reducer";
import cartReducer from "./cart/reducer";
import signInReducer from "./signIn/reducer";
import paymentIntentReducer from "./paymentIntent/reducer";
import logOutReducer from "./logOut/reducer";
import orderHistoryReducer from "./orderHistory/reducer";
import productDetailsReducer from "./productDetails/reducer";
import categoryDropdownReducer from "./categoryDropdown/reducer";
import dropdownMasterReducer from "./dropdownMaster/reducer";

export default combineReducers({
    signUpReducer,
    countriesReducer,
    commonReducer,
    checkoutReducer,
    userReducer,
    cartReducer,
    signInReducer,
    paymentIntentReducer,
    logOutReducer,
    orderHistoryReducer,
    productDetailsReducer,
    categoryDropdownReducer,
    dropdownMasterReducer
})