import { takeLatest,put } from "redux-saga/effects";
import { getMethodWithToken } from "services/apiServices";
import url from 'services/urls.json';
import { CATEGORY_DROPDOWN , CATEGORY_DROPDOWN_ERROR,CATEGORY_DROPDOWN_SUCCESS } from "./constant";
import { ClearTokenRedirectLogin } from "context/context";

function* categoryDropdownApi(action){
    try {
         // Destructure `id` from the action payload
         console.log("abc3",action)
         const { id } = action.data;

         // Construct the URL with the dynamic id
         const urlWithId = `${url.dropdownItem}/?slug=${id}`;
        const response=yield getMethodWithToken(urlWithId)
            if(response.status === 200 || response.status === 201){
                yield put({type:CATEGORY_DROPDOWN_SUCCESS , payload:response})
                if(action.external && action.external.run === true){
                    action.external.handleFunction(response)
                }
                if(action.external && action.external.toast === true){
                    action.external.handleToast(response)
                }
            }
            else {
                yield put({type:CATEGORY_DROPDOWN_ERROR , payload:response})

                if(response.status === 401 || response.status === 403){
                    ClearTokenRedirectLogin(action.external.navigate)
                  }

                if(action.external && action.external.toast === true){
                    action.external.handleToast(response)
                }
                
            }
    }
    catch(error){
        yield put({type:CATEGORY_DROPDOWN_ERROR , payload:error})
    }
}

function* categoryDropdownSaga(){
    yield takeLatest(CATEGORY_DROPDOWN , categoryDropdownApi)
}

export default categoryDropdownSaga