import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { postMethod } from "services/apiServices";
import url from "services/urls.json";
import { toast } from "react-toastify";
import TabSwitch from "components/TabSwitch/TabSwitch";
import { Box } from "@mui/material";
import Ptext from "components/Ptext/Ptext";
import H1text from "components/H1text/H1text";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";

const WrapperDiv = styled.div`
  .MuiInputBase-root {
    border-radius:10px;
    max-height:48px;
    max-width:48px;
  }
`;

const OtpScreen = () => {
    const [otp, setOtp] = React.useState('')
    const navigate = useNavigate();
    const handleChange = (newValue) => {
      setOtp(newValue)
      console.log(newValue.length)
      if(newValue.length==4){
        navigate('/setpassword')
      }
    }

    return (
        <Box style={{width:'100%',height:'62vh',minHeight:'fit-content', display:'flex', alignItems:'center', justifyContent:'center' }}>
        <WrapperDiv>
            <Container style={{ maxWidth: '392px', padding:'0', padding:'16px', paddingBottom:"80px", marginBottom:'184px' }}>
                <Box style={{ textAlign: 'center' }}>
                    <H1text size='36' lineHeight='34' fontWeight='600' marginBottom="34" marginTop={'248'}>Restore Password</H1text>
                    <div >
                        <Ptext size='16' lineHeight='24' fontWeight='500' marginTop="32">A confirmation code to reset password has been sent to your phone number.</Ptext>
                    </div>
                </Box>
                <Box marginTop={'32px'} display='flex' justifyContent={'center'}>
                <MuiOtpInput value={otp} onChange={handleChange} />
                </Box>
                {/* <Box marginTop={'32px'}> 
                    <button type='submit' className='btn btn-dark btn-lg submit mb-5 w-100' style={{marginTop:'32px'}} > 
                    <Ptext size='16' lineHeight='24' fontWeight='600' marginTop="0">Continue</Ptext>
                    </button>
                    </Box> */}
            </Container>
        </WrapperDiv>
        </Box>
    )
}
export default OtpScreen