import CustomCarousel from "components/CustomCarousel/CustomCarousel";
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import { React, useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styled from "styled-components";


const Reviews = () => {

    const reviews = [
        { id: 1, value: "Reviews from companies" },
        { id: 2, value: "Rider profiles" },
        { id: 3, value: "What are people saying" }
    ]
    return (
        <div>
            {reviews && reviews.map((data, index) => (
                <div style={{ background: data?.value === "Rider profiles" ? "#FAFAFA" : '#ffffff', padding:'96px 0' }}>
                    <Container>
                        <Row className="align-items-center ">

                            <Col md={12} >
                                <div className=" d-flex justify-content-between align-items-center">
                                    <H1text size={'36'} lineHeight={'36'} fontWeight={'600'}  className="text-left" >{data?.value}</H1text>
                                    <Button variant="outline-dark" className=" " style={{border:'1px solid #000000',fontWeight:'600', borderRadius:'12px', padding:'12px 24px'}}> <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'}  marginTop="0" >{data?.id==2?'All profile':'All reviews'}</Ptext></Button>
                                </div>
                            </Col>
                            <Col class="carouselDiv" style={{'marginTop':'48px'}}>
                                    {data?.id==1 &&
                                    <CustomCarousel 
                                    slidesToShow={3}
                                    useFor={"Reviews"}
                                    />}
                                    {data?.id==2 && 
                                    <CustomCarousel 
                                    slidesToShow={4}
                                    useFor={"Rider"}
                                    />}
                                    {data?.id==3 && 
                                    <CustomCarousel 
                                    slidesToShow={3}
                                    useFor={"Feedback"}
                                    />}
                            </Col>
                        </Row>
                    </Container>
                </div>
            ))}
        </div>

    )
}
export default Reviews