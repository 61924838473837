import { React, useEffect, useState } from "react";
import { Button, Row, Col, Container, FormControl, InputGroup } from "react-bootstrap";
import moon from 'assets/images/moon_stars.webp'
import { useNavigate, useLocation } from "react-router-dom";
import "pages/Cart/Cart.scss";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import url from 'services/urls.json';
import { useDispatch, useSelector } from "react-redux";
import { commonCallApi } from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";
import { getMethodWithToken, deleteMethodWithToken, updateMethodWithToken } from "services/apiServices";
import checkoutCallApi from "store/checkout/action";
import { CHECKOUT } from "store/checkout/constant";
import cartCallApi from "store/cart/action";
import { CART } from "store/cart/constant";
import userCallApi from "store/user/action";
import { USER } from "store/user/constant";
import { ClearTokenRedirectLogin } from "context/context";
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import { Box } from "@mui/material";

const Cart = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const [cartArray, setCartArray] = useState([])
    const [productDetail, setProductDetail] = useState([])
    const [product, setProduct] = useState('')
    const cartSelector = useSelector(state => state.commonReducer?.cart?.cart);
    const [cartItem, setCartItem] = useState(cartSelector ?? 0);

    const [totalQuantity, setTotalQuantity] = useState(0);
    const [value, setValue] = useState(0);

    const basketId = useSelector(state => state?.userReducer?.payload?.data?.basket_id)
    const handleIncrement = (quantity, product) => {
        setCartItem(quantity + 1);
        setProduct(product)
        setValue(quantity + 1);
        handleQuantity(product.split('api/v1/')[1], quantity + 1)
    };

    const handleDecrement = (quantity, product) => {
        if (quantity > 1) {
            setCartItem(quantity - 1)
            setValue(quantity - 1)
            handleQuantity(product.split('api/v1/')[1], quantity - 1)
        }
        else {
            setCartItem(quantity)
            setValue(quantity);
        }
        setProduct(product)


    };

    const handleQuantity = async (id, quantity) => {
        const cart = localStorage.getItem('cartAPI')
        const apiUrl = `${id}`
        let body = {
            quantity: quantity
        }
        await updateMethodWithToken(apiUrl, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                handleCart()
            }
        })
    }
    const handleChange = (e) => {
        const newValue = parseInt(e.target.value, 10);
        if (!isNaN(newValue) && newValue >= 0) {
            setValue(newValue);
        }
    };
    const handleAddMore = () => {
        navigate('/store')
    }
    const handleCart = (res) => {
        dispatch(cartCallApi(CART, {}, {
            url: res?.data?.basket_id === null ? `ecom/baskets/` : `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleCartCount(res)
        }))
    };


    const handleCartCount = (response) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: response?.data?.[0]?.total_quantity }, { run: true }))
        setCartArray(response.data)
        setValue(response.data.quantity)
        // response.data?.length > 0 && response.data.map((data) => (
        //     handleProductDetail(data?.product.split('api/v1/')[1])
        // ))

        let amount = 0;
        response.data?.length > 0 && response.data.map((data) => {
            return (

                amount = amount + Number(data?.price_incl_tax)
            )

        });

        localStorage.setItem("totalAmount", amount)


    }


    // const itemSet = new Set();
    // const handleProductDetail = async (api) => {
    //     await getMethodWithToken(api).then((response) => {

    //         if (response.status === 200 || response.status === 201) {
    //             let item = response.data
    //             const itemString = JSON.stringify(item);
    //             if (!itemSet.has(itemString)) {
    //                 itemSet.add(itemString);
    //                 setProductDetail(Array.from(itemSet).map(str => JSON.parse(str)))
    //             }
    //         }
    //         else if (response.status === 401 || response.status === 403) {
    //             ClearTokenRedirectLogin(navigate)
    //         }

    //     })

    // }
    const handleClearCart = async (quantity, api) => {
        await deleteMethodWithToken(api).then((response) => {
            if (response.status === 200 || response.status === 204) {
                if (cartItem < 0) {
                    dispatch(commonCallApi(COMMON_DETAILS, { 'cart': 0 }, { run: true }));

                }
                else {
                    dispatch(commonCallApi(COMMON_DETAILS, { 'cart': cartItem - quantity }, { run: true }));
                }
                handleCart(localStorage.getItem('cartAPI'))
            }
        })
    }

    const handleClearAllCart = async () => {

        const api = localStorage.getItem("clearAllCart");
        await deleteMethodWithToken(api).then((response) => {
            if (response.status === 200 || response.status === 204) {
                dispatch(commonCallApi(COMMON_DETAILS, { 'cart': 0 }, { run: true }));
                dispatch(userCallApi(USER, {}, {
                    run: true,
                    handleFunction: (res) => handleCart(res)
                }))

                localStorage.removeItem('cartAPI');

            }
        })
    }

    const handleCheckout = () => {
        navigate('/checkout')
    }

    useEffect(() => {
        handleCart()
    }, [])


    return (
        <div className="cart" style={{ fontFamily: "Manrope, sans-serif", padding:'0 40px'}} onClick={(e) => e.stopPropagation()}>
           
            {cartArray && cartArray?.length > 0 ? (
                <Container fluid className="p-0" style={{  position: 'relative', height: '100vh' }}>
                    <div>
                    <H1text size={'30'} lineHeight={'48'} fontWeight={'600'} marginTop={32}>Cart</H1text>
                    <Ptext size='16' lineHeight='24' fontWeight='600' marginTop='24' >Your purchases</Ptext>
                    {cartArray.map((data, index) => (
                        <div className="filledCart mb-4" style={{ marginTop: '16px' }}>
                            <Row className="product_cart_box d-flex justify-content-between align-items-center mb-2" style={{ padding: '12px' }}>
                                <Col xs={8} className="d-flex justify-content-start align-items-center p-0">
                                    <img src={data?.product_images[0]?.original} alt="cart" className="w-7" style={{ height: "40px", width: '40px' }} />
                                    <div className="mx-3 d-flex">
                                        <div>
                                            <Ptext size="14" lineHeight={'20'} fontWeight='600' marginTop="0" className="text-black mb-1">{data?.product_name?.trim().split(/\s+/).slice(0, 1).join(' ')}</Ptext>
                                            <Box display='flex'>
                                                <Ptext size="14" lineHeight={'20'} fontWeight='700' marginTop="0" className="boldpara">${data?.original_price}</Ptext>
                                                <Ptext size="14" lineHeight={'20'} fontWeight='700' marginTop="0" color={'#c5c5c5'} style={{ marginLeft: '4px' }} >
                                                    x{product == data?.product && value !== 0 ? value : data?.quantity}</Ptext>
                                            </Box>

                                        </div>
                                        <Ptext size="12" lineHeight={'12'} fontWeight='600' marginTop="0" style={{ height: '20px' }} className="buy p-1 text-white px-2 rounded-pill ml-1">Buy</Ptext>
                                        
                                    </div>
                                </Col>


                                            {/* <input type="number" value={product == data?.product && value !== 0 ? value : data?.quantity} min={1} onChange={handleChange} className="border border-1 rounded-4 text-center" style={{ width: '5rem' }} /> */}
                                {/* <Col xs={3}>
                                    <p className="text-black boldpara m-0" >${data?.price_excl_tax}</p>
                                    <Box  className="d-flex" style={{ gap: '10px' }}>
                                            <Button variant="secondary" onClick={() => handleDecrement(data?.quantity, data?.url)} style={{ padding: '4px 6px', height:'22px', display:'flex', alignItems:'center' }} disabled={product == data?.url && value <= 1}>-</Button>
                                            <Button variant="secondary" onClick={() => handleIncrement(data?.quantity, data?.url)} style={{ padding: '4px 6px', height:'22px', display:'flex', alignItems:'center' }}>+</Button>
                                        </Box>
                                </Col> */}
                                <Col xs={1}>
                                    <CloseIcon style={{ color: 'grey', width: '28px', height: '28px', cursor: 'pointer', marginLeft: '-12px' }} onClick={() => handleClearCart(data?.quantity, data?.url)} />
                                </Col>
                            </Row>



                        </div>
                    ))}
                    <div className="add_cart_box mb-2 p-3 d-flex justify-content-center align-items-center" onClick={handleAddMore}>
                        <AddIcon style={{ width: '20px' }} />
                        <Ptext size="14" lineHeight={'20'} fontWeight='600' marginTop="0" className="addMore" style={{ marginLeft: '8px' }}>Add more</Ptext>
                    </div>

                    <div style={{ marginTop: '32px' }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="text-black boldpara">Sub Total</p>
                            <p className="text-black boldpara">${Number(localStorage.getItem("totalAmount"))}</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="text-black boldpara">Tax</p>
                            <p className="text-black boldpara">$0</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="text-black boldpara">Shipping</p>
                            <p className="text-black boldpara">Free Shipping</p>
                        </div>

                    </div>
                    </div>
                    <Box className={'cartFooter'} style={{ width: '100%', paddingBottom: '90px', background:'#ffffff' }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="text-black boldpara">Total</p>
                            <p className="text-black boldpara">${Number(localStorage.getItem("totalAmount"))}</p>
                        </div>
                        <Col md={12} className="mt-2">
                            <Button variant="dark" className=" w-100 fw-bold rentButton " style={{ borderRadius: '12px', padding: '11px' }} onClick={handleCheckout}>Checkout</Button>
                        </Col>

                        <Col md={12} className="">
                            <Button variant="outline-dark" className=" w-100 fw-bold rentButton " style={{ borderRadius: '12px', padding: '11px', marginTop: '16px' }} onClick={handleClearAllCart}>Clear Cart</Button>
                        </Col>
                    </Box>


                </Container>

            )
                : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '85vh' }}>
                        <div className="emptyCart text-center  d-flex justify-content-center flex-column align-items-center">
                            <img src={moon} style={{ width: '30px', height: '30px' }} />
                            <h6 className="text-black mt-4 pb-3 fontCart" >Your shopping cart is empty</h6>
                            <Container>
                                <Row className="justify-content-center">
                                    <Col sm={12}>
                                        <Button variant="dark" className="mt-2 w-100 fontCart" onClick={() => navigate("/store")} >Go to models</Button>
                                    </Col>
                                    {/* <Col sm={4}>
                                    <Button variant="dark" className="mt-2 w-100 fontCart rentButton">Checkout</Button>
                                </Col> */}

                                </Row>
                            </Container>


                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default Cart