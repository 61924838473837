import React, { useEffect, useState } from "react";
import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import "pages/Buy/Buy.scss";
import url from 'services/urls.json';
import { getMethod, getMethodWithToken, postMethodWithToken } from "services/apiServices";
import { useNavigate } from "react-router-dom";
import cartCallApi from "store/cart/action";
import { CART } from "store/cart/constant";
import { commonCallApi } from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";
import { useDispatch, useSelector } from "react-redux";
import userCallApi from "store/user/action";
import { USER } from "store/user/constant";
import { toast } from "react-toastify"
import { ClearTokenRedirectLogin } from "context/context";
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import { Box } from "@mui/material";
import styled from "styled-components";
import productDetailsCallApi from "store/productDetails/action";
import { PRODUCT_DETAILS } from "store/productDetails/constant";

const WrapperImageDiv = styled.div`
  background: url(${props => props.image});
  height: 346px;
  max-width:346px;
  margin:auto;
  marginTop: 20px;
  cursor:pointer;
  background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;
const VehicleShow = ({ productList, onClick, handleSelect, activeIndices}) => {
 
  // const [activeIndices, setActiveIndices] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getProductDetails =(id)=>{
    dispatch(productDetailsCallApi(PRODUCT_DETAILS, { id: id }, {
        run: true,
        handleFunction: () => {navigate(`/productdetails/${id}`); window.scrollTo(0, 0);},
        toast: true,
        handleToast: (error) => { toast.error(error?.data?.detail)}
    }));
    // navigate(`/productdetails/${id}`)
}

console.log("object",productList)
  return (
    <>
      {productList && productList.length > 0 && productList.map((data, index) => (
        <Col lg={6} key={data?.id} className="d-flex" >
          <div className=" h-100 w-100 d-flex flex-column" style={{ background: '#ffffff', borderRadius: '24px', padding: '16px' }}>
            <div style={{ background: '#fafafa', borderRadius: '24px' }}>
              <div className=" p-3">
                <div className="d-flex  align-items-center gap-3 flex-wrap">
                  {data?.attributes.map((attribute, index) => {

                    if (
                      attribute?.name === "Battery Capacity" ||
                      attribute?.name === "Charging Time" ||
                      attribute?.name === "Top Speed"
                    ) {
                      return (
                        <Stack
                          key={index}
                          direction="horizontal"
                          gap={1}
                          style={{ fontWeight: '600' }}
                          className="attributes"
                        >
                          <img
                            src={attribute?.icon}
                            alt={attribute?.name}
                            className="w-4"
                            style={{ marginRight: '4px' }}

                          />
                          <Ptext size={'14'} lineHeight={'18'} fontWeight={'600'} marginTop={'0'} className="mb-0 " >{attribute?.value}</Ptext>
                        </Stack>
                      );
                    }
                    return null;
                  })}


                </div>
              </div>
              <div className="carouselBuy">
                <Carousel
                  activeIndex={activeIndices[data?.id] || 0}
                  onSelect={(index) => handleSelect(index, data?.id)}
                  prevIcon={<div style={{ background: '#f6f6f6', padding: '36px 0', maxWidth: '24px', borderRadius: '12px' }} ><ArrowBackIosIcon style={{ color: 'black', marginLeft: '7px' }} /></div>}
                  nextIcon={<div style={{ background: '#f6f6f6', padding: '36px 0', maxWidth: '24px', borderRadius: '12px' }} ><ArrowForwardIosIcon style={{ color: 'black', marginRight: '7px' }} /></div>}
                  interval={null}
                >
                  {data?.images && data?.images.length > 0 && data?.images.map((item, index) => (
                    <Carousel.Item key={index}>
                      {/* <img src={item?.original} className="d-block mx-auto w-75" alt={`Slide ${index}`} style={{ height: '450px', marginTop: '20px' }} /> */}
                      <div style={{ marginTop: '20px' }}>
                        <WrapperImageDiv image={item?.original} onClick={() => getProductDetails(data?.id)}>

                        </WrapperImageDiv>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
                {/* <div className="text-center fw-bold pt-4 pb-2"><h3>{data?.title}</h3></div> */}
                <div className="d-flex justify-content-center mt-3 " >
                  {data?.images.map((image, index) => (
                    <img
                      key={index}
                      src={image?.original}
                      alt={`Indicator ${index}`}
                      className={`mt-1 indicator-image ${activeIndices[data?.id] === index ? 'active' : ''}`}
                      onClick={() => handleSelect(index, data?.id)}
                      style={{ cursor: 'pointer', width: '64px', height: '64px', objectFit: 'cover', margin: '0 5px', border: (activeIndices[data?.id] === index) ? '2px solid #4579F5' : 'none', borderRadius: '15%' }}
                    />
                  ))}
                </div>
              </div>
              {/* <div className="d-flex justify-content-around align-items-center mt-3 gap-5">
                                        <div className="text-black fw-bold">
                                            <p>Phone mount</p>
                                            <p>${data?.phone_mount}</p>
                                        </div>
                                        <div className="d-flex gap-1 justify-content-start w-75">
                                            <Button variant="outline-dark" className=" w-35 fw-bold CartButton pe-4 ps-4 pt-2 pb-2" onClick={() => handleAddCart(data?.url)}>Buy / {data?.price_incl_tax}</Button>
                                            <Button variant="dark" className=" w-35 fw-bold rentButton pe-4 ps-4 pt-2 pb-2">Rent / {data?.rent}</Button>
                                        </div>
                                    </div> */}
            </div>
            <Row
              style={{
                backgroundColor: "#fafafa",
                // width: "100%",
                borderRadius: "12px",
                padding: "24px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: '0px',
                marginTop: "16px",
              }}
            >
              <Col md={4} lg={3} className={'p-0'}>
                <Ptext size="18" lineHeight={'28'} fontWeight={'600'} marginTop="0">{data?.title}</Ptext>
                <Ptext size="20" lineHeight={'28'} fontWeight={'700'} marginTop="0">${data?.phone_mount}</Ptext>
              </Col>
              <Col md={8} lg={9} className="d-flex p-0 justify-content-start justify-content-md-end mt-2 mt-md-0" style={{ maxHeight: '48px' }}>
                <Button style={{ marginRight: '16px' }} variant="outline-dark" className=" fw-bold CartButton " onClick={() => onClick(data?.url)}>
                  <Ptext size="16" lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>Buy&nbsp;/&nbsp;{data?.price_incl_tax}</Ptext> </Button>
                <Button variant="dark" className=" fw-bold rentButton ">
                  <Ptext size="16" lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>Rent&nbsp;/&nbsp;{data?.rent}</Ptext></Button>
              </Col>
            </Row>
          </div>
        </Col>
      ))}
    </>
  )
}

export default VehicleShow
