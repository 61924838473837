import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import { React, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import womenAndBuilding from 'assets/images/womenAndBuilding.png'

const PublicTransport = () =>{


    return (
        <div style={{fontFamily:"Manrope, sans-serif", paddingTop: '96px'}} >
              <Container className="" >
                        <Row className=" align-items-center">
                           
                            <Col md={6} className="text-left mb-3" >
                               <H1text size={'36'} lineHeight={'36'} fontWeight={'600'} style={{maxWidth:'458px'}}>Don't bare public transportation. <br/> Enjoy the outdoors and explore your city</H1text>
                               <Ptext  size={'14'} lineHeight={'24'} fontWeight={'500'} marginTop={'36'}>Having fun cruising the streets at 20+Mph</Ptext>
                               </Col>
                            <Col md={6}>
                               <img src={womenAndBuilding} className="w-100" style={{borderRadius:'24px', border:'8px solid #ffffff'}}/>

                            </Col>

                        </Row>
                    </Container>
        </div>
    )
}
export default PublicTransport