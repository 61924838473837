import React, { useState } from "react";
import { Container, Row, Col, FloatingLabel, Form, Button } from "react-bootstrap";
import "components/common/perfectScooter/perfectScooter.scss"
import H1text from "components/H1text/H1text";
import Ptext from "components/Ptext/Ptext";
import PlaceholderInput from "components/Inputs/PlaceholderInput/PlaceholderInput";


const PerfectScooter = (props) => {

    const typeData = [
        {
            slug:"one",
            name:"One"
        },
        {
            slug:"two",
            name:"Two"
        },
        {
            slug:"three",
            name:"Three"
        },
    ]
    const [formValue, setFormvalue] = useState({
        "type": "",
        "sortByPrice": "",
        "itemsPerPageOptions": "",
    });
    const handleChange = (e) => {
        console.log("abc", e)
        const { name, value } = e.target;
        setFormvalue((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    console.log("mkmk",props?.models)
    return (
        <div className="perfectScooter" style={{ paddingBottom: '96px', backgroundColor: props.bgColor? props.bgColor :'#fafafa' }} >
            <Container className=" containerScooter">
                <Row style={{ background: "#ECF1F4", borderRadius: "30px", padding: '48px' }}>
                    <Col md={7}>
                        <div>
                            <H1text size={'36'} lineHeight={'36'} fontWeight={'600'} className="text-black" marginTop={0} style={{ maxWidth: '309px' }}>{props.heading}</H1text>
                            <div className="form_box" style={{ marginTop: '38px' }}>
                                <form>
                                    <Row>
                                        <Col md={6} style={{ marginBottom: '20px' }} >
                                            <PlaceholderInput
                                                label={props.for}
                                                placeholder={props.for}
                                                data={props?.models}
                                                value={formValue?.type}
                                                name={"type"}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </Col>
                                        {/* <Col md={6} style={{ marginBottom: '20px' }} >
                                            <FloatingLabel controlId="floatingSelect" label={props.for} className="label" >
                                                <Form.Select aria-label="Floating label select example" className="backgroundForm backgroundFormOption" >
                                                    <option>
                                                        Open this select menu</option>
                                                    <option value="1">
                                                        One</option>
                                                    <option value="2">
                                                        Two</option>
                                                    <option value="3">
                                                        Three</option>
                                                </Form.Select>
                                            </FloatingLabel></Col> */}
                                        <Col md={6} style={{ marginBottom: '20px' }}>
                                            <input type='text' placeholder={'Your name'} name='name' autoFocus className="form-control backgroundForm" />
                                        </Col>
                                        <Col md={6} style={{ marginBottom: '20px' }}>
                                            <input type='email' placeholder='Your email' name='name' autoFocus className="form-control backgroundForm" /></Col>
                                        <Col md={6} style={{ marginBottom: '20px' }}><input type='text' placeholder='Your phone' name='name' autoFocus className="form-control backgroundForm" /></Col>
                                        <Col md={6} className="mb-3 mb-md-0">  <Button variant="dark" className=" orderButton w-100" type="submit">  <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>Order right now</Ptext></Button></Col>
                                        <Col md={6} > <button className="payButton w-100 d-flex justify-content-center gap-1"><img src="https://metrorides.theclientdemos.com/assets/images/apple_music.svg" /> <Ptext size={'16'} lineHeight={'24'} fontWeight={'600'} marginTop={'0'}>Apple pay</Ptext></button></Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <div className="scooterImage d-none d-md-block" style={{ position: 'relative' }} >
                            <img src={props.image} style={{ position: 'absolute', top: props.top }} /></div></Col>
                </Row>
            </Container>
        </div>
    )

}
export default PerfectScooter