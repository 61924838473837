import React, { useState, useEffect } from "react";
import 'pages/orderList/orderList.scss';
import { Container, Row, Col, Button } from "react-bootstrap";
import scooter from "assets/images/orderListScooter.png";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_HISTORY } from "store/orderHistory/constant";
import orderHistoryCallApi from "store/orderHistory/action";
import moment from 'moment';

const OrderList = () => {

    const array = [1, 2, 3, 4, 5];
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const orderList = useSelector((state)=>state?.orderHistoryReducer?.payload?.data)

    useEffect(()=>{
        getOrderList();
        console.log("abc",orderList)
    },[])

    const handleOrderDetail=()=>{
        navigate('/orderdetail')
        // orderHistoryCallApi
    }
    const getOrderList = () => {
        dispatch(orderHistoryCallApi(ORDER_HISTORY, {}, {run: true, handleFunction: ()=>{}}))
      };

    return (
        <div className="orderList" style={{ fontFamily: "Manrope, sans-serif" }}>
            <Container>
                <Row>
                    <p className="orderHeading">Your order history</p>
                    <Col md={12} className="mb-5">
                        {orderList?.length > 0 && orderList.map((item, index) => (
                            <div className="mb-3 items" >
                                <div className="deliveryDate">
                                    <p className=" p-2 fw-bold">Out for delivery
                                        <span className="date ps-2">Expected to arrive Today</span>
                                    </p>
                                </div>
                                <Container className="p-3">
                                    <Row className="justify-content-between flex-lg-row flex-column ">
                                        <Col md={8} lg={10} xl={8} xxl={8} sm={8} xs={8}>
                                            <Row>
                                                <Col xs={3} md={2} className="pe-xl-0 pe-1 pe-lg-2 ">
                                                    <img src={scooter} alt="scooter" className="orderImage" />
                                                    {/* <img src={item?.product_image?.[0]?.original} alt="scooter" className="orderImage" /> */}
                                                </Col>
                                                <Col xs={8} md={10} className="p-md-0 p-lg-0 p-xl-0">
                                                    <p className="orderName mb-2">The city </p>
                                                    <div className="d-md-flex gap-2">
                                                        <p className="price mb-0">Item Price: <span className="priceValue">$ {item?.total_excl_tax}</span></p>
                                                        <p className="price mb-0">Quantity:<span className="quantityValue">{item?.quantity}</span></p>
                                                    </div>
                                                    <div className="d-lg-flex gap-2 pt-sm-1 d-none">
                                                        <p className="price mb-0">Order Total:
                                                            <span className="priceValue">$ {item?.total_incl_tax}</span>
                                                        </p>
                                                        <p className="price mb-0">Order placed on:
                                                            <span className="priceValue">{ moment(item?.date_placed).format('DD MMM YYYY, dddd')}</span>
                                                        </p>
                                                        <p className="price mb-0">Order ID:
                                                            <span className="priceValue">{item?.number}</span>
                                                        </p>
                                                    </div>

                                                </Col>
                                                <div className="d-sm-block gap-2 pt-sm-3 d-lg-none pt-xs-2">
                                                    <p className="price mb-1" style={{ fontSize: '14px' }}>Order Total:
                                                        <span className="priceValue">$250</span>
                                                    </p>
                                                    <p className="price mb-1" style={{ fontSize: '14px' }}>Order placed on:
                                                        <span className="priceValue">05 Aug 2024, Sunday</span>
                                                    </p>
                                                    <p className="price mb-1" style={{ fontSize: '14px' }}>Order ID:
                                                        <span className="priceValue">4569874562123</span>
                                                    </p>
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col md={12} lg={4} xxl={4} sm={12} xs={12}>
                                            <div className="d-flex justify-content-around pt-3 w-100 gap-3 align-items-center justify-content-xl-end">
                                                <div className="d-flex align-items-center gap-2">
                                                    <FileDownloadOutlinedIcon />
                                                    <p className="invoice mb-0">Invoice</p>

                                                </div>
                                                <Button variant="outline-dark" className="fw-bold" >Order details</Button>
                                                {/* onClick={handleOrderDetail} paste this up for functionality */}
                                                
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>

                            </div>
                        ))}

                    </Col>
                </Row>
            </Container>

        </div>

    )
}

export default OrderList;