import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FloatingLabel, Button, Stack, } from "react-bootstrap";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import 'pages/Compare/Compare.scss';
import url from 'services/urls.json';
import { getMethod, getMethodWithToken, postMethodWithToken } from "services/apiServices";
import { CleaningServices } from "@mui/icons-material";
import { ClearTokenRedirectLogin } from "context/context";
import { useNavigate } from "react-router-dom";
import H1text from "components/H1text/H1text";
import PlaceholderInput from "components/Inputs/PlaceholderInput/PlaceholderInput";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Rating from "components/Rating/Rating";
import Ptext from "components/Ptext/Ptext";
import userCallApi from "store/user/action";
import { USER } from "store/user/constant";
import cartCallApi from "store/cart/action";
import { CART } from "store/cart/constant";
import { toast } from "react-toastify"
import { commonCallApi } from "store/common/action";
import { COMMON_DETAILS } from "store/common/constant";

const WrapperImageDiv = styled.div`
  background: url(${props => props.image});
  height: 346px;
  max-width:346px;
  margin:auto;
  marginTop: 20px;
  cursor:pointer;
  background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height:220px;
`;

const Compare = () => {
    const navigate = useNavigate()

    const [options, setOptions] = useState([])
    const [firstId, setFirstId] = useState("")
    const [secondId, setSecondId] = useState("")
    const [products, setProducts] = useState([])
    const [features, setFeatures] = useState([])
    const dispatch = useDispatch();

    const commonId = useSelector((state) => state?.commonReducer?.id)

    const handleDropdown = async () => {
        let apiUrl = `${url.buy}?catagory=scooter&paginate=false&page_limit=0`;
        let response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
        console.log(response, "22")
        if (response.status === 200 || response.status === 201) {
            console.log(response.data, "23")
            setOptions(response.data)
        }
        else if (response.status === 401 || response.status === 403) {
            ClearTokenRedirectLogin(navigate)
        }
    }
    const handleCompare = async () => {
        if (firstId === '' && secondId === '') {
            console.log("object", "1")
            let apiUrl = url.compare;
            let response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
            if (response.status === 200 || response.status === 201) {
                setProducts(response.data)
                if (commonId?.id) {
                    setFirstId(commonId?.id)
                    // setSecondId("");
                } else {
                    setFirstId(response?.data?.[0]?.id);
                }
                setSecondId(response.data[1].id);
                response?.data.map((data, index) => {
                    setFeatures(data?.attributes)

                })
            }
            else if (response.status === 401 || response.status === 403) {
                ClearTokenRedirectLogin(navigate)
            }
        }
        else {
            console.log("object", "2")
            let apiUrl = `${url.compare}?product_first=${firstId}&product_second=${secondId}`
            let response = localStorage.getItem("token") ? await getMethodWithToken(apiUrl) : await getMethod(apiUrl)
            if (response.status === 200 || response.status === 201) {
                setProducts(response.data)
                response?.data.map((data, index) => {
                    setFeatures(data?.attributes)
                })

            }
            else if (response.status === 401 || response.status === 403) {
                ClearTokenRedirectLogin(navigate)
            }
        }

    }

    const handleFirstProduct = (e) => {
        setFirstId(e.target.value)
    }

    const handleSecondProduct = (e) => {
        setSecondId(e.target.value);
    }

    console.log(firstId, secondId)
    useEffect(() => {
        handleDropdown()

    }, [])
    useEffect(() => {
        handleCompare()
    }, [firstId, secondId])


    const [formValue, setFormvalue] = useState({
        "scooter1": "",
        "scooter2": "",
    });
    const handleChange = (e) => {
        // const { name, value } = e.target;
        // setFormvalue((prev) => ({
        //     ...prev,
        //     [name]: value
        // }));
        if (e.target.name == "scooter1") {
            setFirstId(e.target.value)
        }
        if (e.target.name == "scooter2") {
            setSecondId(e.target.value);
        }

    };

    const handleAddCart = async (product) => {

        let body = {
            "url": product,
            "quantity": 1
        }

        await postMethodWithToken(url.addCart, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                const basket = response.data.lines.split('/api/v1/')[1]
                toast.success("Item Added to cart")
                dispatch(userCallApi(USER, {}, {
                    run: true,
                    handleFunction: (res) => handleCartCount(res?.data?.basket_id)
                }))

                localStorage.setItem('cartAPI', basket)
                localStorage.setItem("clearAllCart", response.data.url)
            }
            else if (response.status === 401) {
                navigate('/signin')
            }
            else if (response.status === 406) {
                toast.error("Out Of Stock")
            }
        })

    }


    const handleCartCount = (basketId) => {
        dispatch(cartCallApi(CART, {}, {
            url: `ecom/baskets/${basketId}/lines`,
            run: true,
            handleFunction: (res) => handleSuccess(res)
        }))
    };

    const handleSuccess = (res) => {
        dispatch(commonCallApi(COMMON_DETAILS, { cart: res?.data?.[0]?.total_quantity }, { run: true }))
    };
    return (
        <div style={{ fontFamily: "Manrope, sans-serif", background: '#fafafa', paddingTop: '58px', paddingBottom: '96px' }} className="compare">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={4}>
                        <H1text size={'36'} lineHeight={'36'} fontWeight={600} marginTop='0'>Compare</H1text>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="form_box d-flex gap-2 justify-content-md-end justify-content-start mt-2 mt-md-0">
                            {/* <Row style={{ width: '100%', justifyContent: 'end' }}> */}
                            {/* <Col className="inputSize" sm={12} md={6}> */}
                            {/* <FloatingLabel controlId="floatingSelect" label="Scooter #1" className="label labelWidth" style={{ fontSize: '12px' }} >
                                    <Form.Select aria-label="Floating label select example" value={firstId} className="backgroundForm backgroundFormExtra" style={{ borderRadius: '20px', fontWeight: '600' }} onChange={handleFirstProduct}>
                                        {options.length>0 && options.map((data, index) => (
                                            <option value={data?.id} style={{ fontWeight: '600' }}>{data.title.trim().split(/\s+/).slice(0, 3).join(' ')}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel> */}
                            <PlaceholderInput
                                label={'Scooter #1'}
                                placeholder={'Scooter #1'}
                                data={options}
                                value={firstId}
                                name={'scooter1'}
                                onChange={(e) => handleChange(e)}
                            />
                            {/* </Col> */}


                            {/* </Row> */}

                        </div>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="form_box d-flex gap-2 justify-content-md-end justify-content-start mt-2  mt-md-0">
                            {/* <Row style={{ width: '100%', justifyContent: 'end' }}> */}
                            {/* <Col sm={12} md={6} className="inputSize mt-2 mt-md-0"> */}
                            {/* <FloatingLabel controlId="floatingSelect" label="Scooter #2" className="label labelWidth" style={{ fontSize: '12px' }} >
                                    <Form.Select aria-label="Floating label select example" value={secondId} className="backgroundForm backgroundFormExtra" style={{ borderRadius: '20px', fontWeight: '600' }} onChange={handleSecondProduct}>
                                        {options.length>0 && options.map((data, index) => (
                                            <option value={data?.id} style={{ fontWeight: '600' }} >{data.title.trim().split(/\s+/).slice(0, 3).join(' ')}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel> */}
                            <PlaceholderInput
                                label={'Scooter #2'}
                                placeholder={'Scooter #2'}
                                data={options}
                                value={secondId}
                                name={'scooter2'}
                                onChange={(e) => handleChange(e)}
                            />
                            {/* </Col> */}


                            {/* </Row> */}

                        </div>
                    </Col>
                </Row>
                <Row className="" style={{ marginTop: '42px' }} >
                    {products && products.length > 0 && products.map((data, index) => (
                        data?.id == firstId &&
                        <Col xs={6} lg={4} key={index} className="d-none d-lg-flex">
                            <div style={{ background: '#fafafa', padding: '16px', borderRadius: '24px', width: '100%' }}>
                                <div style={{ visibility: 'hidden' }}>
                                    <Stack direction="horizontal" gap={3}>
                                        <div className="d-flex justify-content-between align-items-center gap-1 "><span className="fw-bold " style={{ background: '#fafafa', borderRadius: '28px', padding: '4px 12px' }}>4/5</span></div>
                                        <div className="p-2 ms-auto d-flex gap-2 fw-bold"><div ><FavoriteBorderIcon style={{ width: '20px', height: '20px' }} /></div> <div><ShoppingCartOutlinedIcon style={{ width: '20px', height: '20px' }} /></div></div>
                                    </Stack>
                                    <H1text size='30' lineHeight='36' fontWeight='600' className="fw-bold text-black mt-2 mb-4">{data?.title}</H1text>
                                    <div >
                                        {/* <img src={data?.images[0]?.original} className="mx-auto" style={{height:'300px'}} /> */}
                                        <WrapperImageDiv image={data?.images[0]?.original} onClick={() => navigate(`/productdetails/${data?.id}`)}>

                                        </WrapperImageDiv>
                                    </div>

                                </div>
                                <div style={{ background: '#ffffff', borderRadius: '20px', padding: '24px 16px 16px 16px' }} className=" px-3 pt-3 pb-3 mt-3 mb-2">
                                    {data?.attributes && data?.attributes.map((item, index) => (
                                        <>
                                            {/* <div className=" d-flex d-lg-none align-items-center gap-2" style={{ background: '#e1e1e1', borderRadius: '8px', padding: '0 6px' }} >
                                                <img src={item?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5' }} />
                                                <Ptext size='14' lineHright='24' fontWeight='700' marginTop="0">{item?.name}</Ptext>
                                            </div> */}
                                            <Row key={index} gap={2} style={{ maxHeight: '40px', minHeight: '40px' }} className="flex-wrap pb-3 justify-content-between minMaxXs">
                                                <Col xs={12} className="d-flex  align-items-center">
                                                    <img src={item?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5', marginRight: '12px' }} />
                                                    <Ptext size='14' lineHright='24' fontWeight='700' marginTop="0">
                                                        {item?.name}
                                                    </Ptext>
                                                </Col>
                                                {/* <Col xs={12} lg={6} className="mt-2 mt-lg-0 d-flex justify-content-start justify-content-lg-end">
                                                    <Rating rating={4} />
                                                </Col> */}
                                            </Row>
                                        </>
                                    ))}
                                </div>
                                {/* <Stack direction="vertical" gap={3} style={{visibility:'hidden'}}>
                                    <Button variant="outline-dark p-2 buyButton "> Buy /  ${data?.price_incl_tax}</Button>
                                    <Button className="bg-dark rentButton buyButton p-2">Rent / ${data?.rent}</Button>
                                </Stack> */}
                            </div>
                        </Col>
                    ))}

                    {products && products.length > 0 && products.map((data, index) => (
                        data?.id == firstId &&
                        <Col key={index} xs={6} lg={4} className={'removePaddingXs'}>
                            <div className={'removePaddingXs'} style={{ background: '#ffffff', padding: '16px', borderRadius: '24px' }}>
                                <div>
                                    <Stack direction="horizontal" gap={3}>
                                        <div className="d-flex justify-content-between align-items-center gap-1 "><span className="fw-bold " style={{ background: '#fafafa', borderRadius: '28px', padding: '4px 12px' }}>4/5</span></div>
                                        <div className="p-2 ms-auto d-flex gap-2 fw-bold"><div ><FavoriteBorderIcon style={{ width: '20px', height: '20px' }} /></div> <div>
                                        <ShoppingCartOutlinedIcon className="cursor-pointer" style={{ width: '20px', height: '20px' }}  onClick={() => handleAddCart(data?.url)}/>
                                            </div></div>
                                    </Stack>
                                    <h1 className="responsiveText text-black mt-2 mb-4">{data?.title}</h1>
                                    <div >
                                        {/* <img src={data?.images[0]?.original} className="mx-auto" style={{height:'300px'}} /> */}
                                        <WrapperImageDiv image={data?.images[0]?.original} onClick={() => navigate(`/productdetails/${data?.id}`)}>

                                        </WrapperImageDiv>
                                    </div>

                                </div>
                                <div style={{ background: '#f8f9fa', borderRadius: '20px', padding: '24px 16px 16px 16px' }} className="removePaddingXs px-3 pt-3 pb-3 mt-3 mb-2">
                                    {data?.attributes && data?.attributes.map((item, index) => (
                                        <>
                                            <div key={index} className=" d-flex d-lg-none align-items-center gap-2 mt-4 mt-lg-0 mb-1 mb-lg-0" style={{ background: '#e1e1e1', borderRadius: '8px', padding: '0 6px' }} >
                                                <img src={item?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5' }} />
                                                <Ptext size='14' lineHright='24' fontWeight='700' marginTop="0" 
                                                style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{item?.name}</Ptext>
                                            </div>
                                            <Row gap={2} className="flex-wrap pb-3 justify-content-between minMaxXs" style={{ maxHeight: '40px', minHeight: '40px' }} >
                                                <Col xs={12} lg={6} className="">
                                                    <Ptext size='14' lineHright='24' fontWeight='700' marginTop="0"
                                                     style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}
                                                    >
                                                        {item?.value}
                                                    </Ptext>
                                                </Col>
                                                <Col xs={12} lg={6} className="mt-2 mt-lg-0 d-flex justify-content-start justify-content-lg-end">
                                                    <Rating rating={4} />
                                                </Col>
                                            </Row>
                                        </>
                                    ))}
                                </div>
                                <Stack direction="vertical" gap={3}>
                                    <Button variant="outline-dark p-2 buyButton " onClick={() => handleAddCart(data?.url)}> Buy /  ${data?.price_incl_tax}</Button>
                                    <Button className="bg-dark rentButton buyButton p-2">Rent / ${data?.rent}</Button>
                                </Stack>
                            </div>
                        </Col>
                    ))}

                    {products && products.length > 0 && products.map((data, index) => (
                        data?.id == secondId &&
                        <Col key={index} xs={6} lg={4} className={'removePaddingXs'} >
                            <div className={'removePaddingXs'} style={{ background: '#ffffff', padding: '16px', borderRadius: '24px' }}>
                                <div >
                                    <Stack direction="horizontal" gap={3}>
                                        <div className="d-flex justify-content-between align-items-center gap-1 "><span className="fw-bold" style={{ background: '#fafafa', borderRadius: '28px', padding: '4px 12px' }}>4/5</span></div>
                                        <div className="p-2 ms-auto d-flex gap-2 fw-bold"><div >
                                            <FavoriteBorderIcon style={{ width: '20px', height: '20px' }} />
                                        </div>
                                            <div>
                                                <ShoppingCartOutlinedIcon className="cursor-pointer" style={{ width: '20px', height: '20px' }}  onClick={() => handleAddCart(data?.url)}/>
                                            </div>
                                        </div>
                                    </Stack>
                                    <h1 className="responsiveText text-black mt-2 mb-4">{data?.title}</h1>
                                    <div>
                                        {/* <img src={data?.images[0]?.original} className="mx-auto" style={{ height: '300px' }} /> */}
                                        <WrapperImageDiv image={data?.images[0]?.original} onClick={() => navigate(`/productdetails/${data?.id}`)}>

                                        </WrapperImageDiv>
                                    </div>

                                </div>
                                <div style={{ background: '#f8f9fa', borderRadius: '20px', padding: '24px 16px 16px 16px' }} className="removePaddingXs px-3 pt-3 pb-3 mt-3 mb-2">
                                    {data?.attributes && data?.attributes.map((item, index) => (
                                        <>
                                            <div key={index} className=" d-flex d-lg-none align-items-center gap-2 mt-4 mt-lg-0 mb-1 mb-lg-0" style={{ background: '#e1e1e1', borderRadius: '8px', padding: '0 6px' }} >
                                                <img src={item?.icon} style={{ width: '15px', height: '15px', color: '#c5c5c5' }} />
                                                <Ptext size='14' lineHright='24' fontWeight='700' marginTop="0"
                                                 style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}
                                                >{item?.name}</Ptext>
                                            </div>
                                            <Row gap={2} className="flex-wrap pb-3 justify-content-between minMaxXs" style={{ maxHeight: '40px', minHeight: '40px' }}>
                                                <Col xs={12} lg={6} className="">
                                                    <Ptext size='14' lineHright='24' fontWeight='700' marginTop="0"
                                                     style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}
                                                    >
                                                        {item?.value}
                                                    </Ptext>
                                                </Col>
                                                <Col xs={12} lg={6} className="mt-2 mt-lg-0 d-flex justify-content-start justify-content-lg-end">
                                                    <Rating rating={4} />
                                                </Col>
                                            </Row>
                                        </>
                                    ))}
                                </div>
                                <Stack direction="vertical" gap={3}>
                                    <Button variant="outline-dark p-2 buyButton " onClick={() => handleAddCart(data?.url)}> Buy /  ${data?.price_incl_tax}</Button>
                                    <Button className="bg-dark rentButton buyButton p-2">Rent / ${data?.rent}</Button>
                                </Stack>
                            </div>
                        </Col>
                    ))}


                </Row>

            </Container>
        </div>
    )
}

export default Compare