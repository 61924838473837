import React, { useEffect, useState } from "react";
import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
import PerfectScooter from "components/common/perfectScooter/perfectScooter";
import 'pages/OurVision/OurVision.scss'
import Ptext from "components/Ptext/Ptext";
import H1text from "components/H1text/H1text";
import womenAndBuilding from 'assets/images/womenAndBuilding.png';

const OurVision = () => {


    return (
        <div style={{ fontFamily: "Manrope, sans-serif" }} className="ourVision ps-2 pe-2 ps-md-0 pe-md-0">

            <div style={{ padding: '96px 0 96px 0', background: '#fafafa' }}>
                <Container>
                    <Row className="justify-content-between align-items-end" >
                        <Col md={4}>
                            <div className="">
                                <H1text size='54' lineHeight='54' fontWeight="600" className="heading text-black relative pt-5 mt-5" marginTop="0">Our vision</H1text>
                                <Ptext size='20' lineHeight='32' fontWeight="500" marginTop="36">Emphasis needs to be put on: 1 in 10 scooters donated to kids who need it most.
                                    Until we reach the goal of 1 million. Then we will continue to see how we can help the world even more</Ptext>
                            </div>
                        </Col>
                        <Col md={7}>
                            <div>
                                <img src={womenAndBuilding} className="w-100" style={{ borderRadius: '24px', border: '8px solid #ffffff' }} />

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <div style={{padding:'96px 0 96px 0', background:'#fafafa'}}> */}
            <Container style={{ padding: '96px 0 96px 0' }}>
                <Row className="justify-content-between align-items-end " >
                    <Col md={6}>
                        <div>
                            <img src="https://metrorides.theclientdemos.com/assets/images/a2.png" alt="img" className="w-100" />

                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="marginDiv">
                            <H1text size='36' lineHeight='36' fontWeight="600" className="heading text-black relative pt-5 mt-5" marginTop="0">We keep things
                                ECO-Friendly, as well
                                as contributing to life changing causes</H1text>
                            <Ptext size='14' lineHeight='24' fontWeight="500" marginTop="36">We will work directly with School Boards, Metro Communities, and Students personally. Ensuring those in need receive an effective form of transportation, new or fresh refurbished rides. A win for the carbon footprint, and a great use of resources. It is our goal to remain carbon positive as long as we can serve you. </Ptext>
                        </div>
                    </Col>

                </Row>
            </Container>
            {/* </div> */}


            <div>
                <PerfectScooter
                    heading={'Your own perfect scooter'}
                    for={'Scooter'}
                    image={'https://metrorides.theclientdemos.com/assets/images/cta.png'}
                    top={'-96px'}
                    //   models={Models}
                    bgColor={'#ffffff'}
                />
            </div>
        </div>
    )
}

export default OurVision