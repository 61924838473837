import { React, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../SignIn/SignIn.scss';
import Button from 'react-bootstrap/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import url from 'services/urls.json'
import { postMethod } from 'services/apiServices';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { signupCallApi } from 'store/SignUp/action';
import { SIGN_UP } from 'store/SignUp/constant';
import Ptext from 'components/Ptext/Ptext';
import CheckIcon from '@mui/icons-material/Check';
import H1text from 'components/H1text/H1text';
import checkSvg from 'assets/svg/check.svg'
import { Box } from '@mui/material';
const Success = () => {

    const navigate = useNavigate();
    const params = useParams();
    useEffect(()=>{
console.log(params)
    },[])

    return (
        <Box style={{ width: '100%', height: '62vh', minHeight: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '392px', padding: '16px' }} >
                <div className='SignIn'>
                    <Container className='' style={{ marginTop: '236px', marginBottom: '241px' }}>
                        <div>
                            <div md={4} className='mx-auto text-center'>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={checkSvg} style={{ height: '25px', width: '26px' }} />
                                    {params.id!=='mail' ?
                                        <H1text size='36' lineHeight='36' fontWeight='600' marginTop={'24'}>Congratulations!</H1text> :
                                        <H1text size='36' lineHeight='36' fontWeight='600' marginTop={'24'}>Reset Password!</H1text>
                                    }
                                </div>
                                <div >
                                    {params.id!=='mail' ?
                                        <Ptext size='16' lineHeight='24' fontWeight='500' marginBottom='0' marginTop='24'>  You successfully signed up in Metro   </Ptext> :
                                        <Ptext size='16' lineHeight='24' fontWeight='500' marginBottom='0' marginTop='24'>  An email with a password reset link has been sent to your inbox  </Ptext>
                                    }
                                    <button type='submit' className='btn btn-dark btn-lg submit mb-4 w-100 cursor-pointer' style={{ marginTop: '32px' }} onClick={() => navigate('/signin')}>Go to Home Page</button>
                                </div>


                            </div>
                        </div>
                    </Container>

                </div>
            </div>
        </Box>
    )
}

export default Success