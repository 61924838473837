import React from "react";
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Ptext from "components/Ptext/Ptext";

// Wrapper for the TextField
const WrapperTextField = styled(TextField)`
  .MuiPaper-root {
    padding: 0 !important;
  }
  .MuiInputBase-root {
    border: 1px solid #dfdfdf; 
    border-radius: 12px; 
    height: 48px;
    margin-top:0;
    overflow:hidden;
  }
  .MuiSelect-select{
    padding-top:30px !important;
  }
  .MuiFormLabel-root{
    margin-top:8px !important;
    color:#000000;
  }
  .MuiSelect-select {
    padding: 14px;
    border-radius: 12px !important; 
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-bottom: none !important; 
  }
  
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important; 
  }
  
  svg {
    margin-right: 12px;
    color:#000000;
  }
  .MuiFormLabel-root {
    padding-left:16px;
    margin-top:-5px !important;
  }
  [data-shrink="false"] {
    padding-left:20px;
    color: #808080 !important;
    font-size:14px;
    line-height:20px;
    font-weight:500;
    
  }
  [data-shrink="true"] {
    padding-left:20px;
    margin-top: 9px !important;
    color: #000000 !important;
    font-size:14px;
    line-height:20px;
    font-weight:500;
    font-family: 'Manrope' ;
  }
`;
const StyledFormControl = styled(FormControl)`
  width: 100% !important;
`;
// Styled MenuItem for the placeholder
const PlaceholderMenuItem = styled(MenuItem)`
  color: red; // Set the color for the placeholder
`;

function PlaceholderInput({ containerProps, ...props }) {
  return (
    <StyledFormControl fullWidth sx={{ all: 'unset' }}>
      <WrapperTextField
        select
        id="standard-required"
        variant="standard"
        fullWidth={true}
        value={props.value || ""} // Set value to empty string if nothing is selected
        onChange={props.onChange}
        SelectProps={{
          IconComponent: KeyboardArrowDownIcon // Use your custom icon here
        }}
        {...props}
      >
        {/* "Clear selection" option */}
        <MenuItem value="">
          <Ptext size={'14'} lineHeight={'20'} fontWeight={'500'} marginTop={'0'}>
            {props.clearText || 'None'}
          </Ptext>
        </MenuItem>

        {/* Options from data */}
        {props?.data?.length > 0 ? (
          props.data.map((k, i) => (
            <MenuItem key={i} onClick={(e) => props?.getValue && props.getValue(e, k)} value={k?.slug || k?.label || k?.id}>
              <Ptext size={'14'} lineHeight={'20'} fontWeight={'500'} marginTop={'0'}>
                {k?.name || k?.value || k?.title}
              </Ptext>
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled value="">
            No options available
          </MenuItem>
        )}
      </WrapperTextField>
    </StyledFormControl>
  );
};

PlaceholderInput.defaultProps = {
  height: 56,
  inputPadding: "14px",
  fontFamily: 'Manrope',
  placeholder: 'Select an option', // Set a default placeholder
  clearText: 'None', // Add default "clear" text
  data: []
};

export default PlaceholderInput;
