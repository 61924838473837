// CommonButton.js
import React from 'react';
import styled from 'styled-components';

// Styled button component
const Button = styled.button`
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  margin-top: ${(props) => props.marginTop};
  padding: 12px 20px;
  border: ${(props) => props.border};
  cursor: pointer;
  border-radius: 12px;
  font-size: 16px;
  line-height:24px;
  font-weight: 600;
  font-family:'Manrope';
  display:flex;
  justify-content:center;
//   transition: background-color 0.3s ease;
  width:100%;
//   &:hover {
//     background-color: ${(props) => props.hoverBackground};
//   }
`;

const BasicButton = (props) => {
    return (
        <Button {...props}>
           {props?.icon && <img src={props.icon} style={{width: '52px'}} />} 
            {props?.text}
        </Button>
    );
};

// Adding default props
BasicButton.defaultProps = {
    text: 'Button',
    background: '#f6f6f6',
    color: '#1d1d1d',
    marginTop: '0px',
    hoverBackground: '#e0e0e0',
    // onClick: () => alert('Default Button Clicked'),
};

export default BasicButton;
