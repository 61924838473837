import { React, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../SignIn/SignIn.scss';
import Button from 'react-bootstrap/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useNavigate } from 'react-router-dom';
import url from 'services/urls.json'
import { postMethod } from 'services/apiServices';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { signupCallApi } from 'store/SignUp/action';
import { SIGN_UP } from 'store/SignUp/constant';
import Ptext from 'components/Ptext/Ptext';
import { Box } from '@mui/material';
import H1text from 'components/H1text/H1text';
const SignIn = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [userName , setUserName]=useState('')
    const [email , setEmail]=useState('')
    const [password , setPassword]=useState('')
    const [confirmPassword , setConfirmPassword]=useState('')
    const [validEmail , setValidEmail]=useState(true)
    const [error, setError] = useState(''); 
    const [pwdError, setPwdError] = useState('');

    const signUpData = useSelector(state=>state?.signUpReducer?.payload?.data)
   
    const handleNameChange = (e) => {

       setUserName(e.target.value)
    };
    const handleEmailChange = (e) => {
       
        setEmail(e.target.value)
     };

     const validatePassword = (pwd) => {
        if (pwd.length < 8) {
          return 'Password must be at least 8 characters long with at least one digit , one special character and one Capital character';
        }
        return '';
      };
      const validateConfirmPassword = (pwd)=>{
        if(password!== pwd){
            return 'confirm password not same as password';
        }
      }
     const handlePasswordChange = (e) => {
        
        setPassword(e.target.value)
        setError(validatePassword(e.target.value));
     };
     const handleConfirmPasswordChange = (e) => {
       
        setConfirmPassword(e.target.value)
        setPwdError(validateConfirmPassword(e.target.value))
     };
    const getFormData = (e) => {
        e.preventDefault()
        let body = {
            name: 'userName',
            email: email,
            password1: password,
            password2: password
        }
        // 'confirmPassword'
        register(body)
    }

    const register = async (body) => {
        dispatch(signupCallApi(SIGN_UP,body,{run:true,
             handleFunction:(res)=>navigate("/success/signup")}));
    }

    // const handleSuccess = (res)=>{
    //     console.log(res)
          
    // }

    useEffect(() => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (email && !emailRegex.test(email)) {
          setValidEmail(false);
        } else {
          setValidEmail(true);
        }
      }, [email]);

    return (
        <Box style={{width:'100%',height:'62vh',minHeight:'fit-content', display:'flex', alignItems:'center', justifyContent:'center' }}>
        <div className='SignIn' style={{width:'392px', padding:'16px'}}>
            {/* <Container className='mt-1 mb-5 pt-1 pb-5'>
                <Row>
                    <Col md={4} className='mx-auto text-center'> */}
                        <div style={{marginTop:'122px'}}>
                              <H1text size='36' lineHeight='36' fontWeight='600' style={{marginBottom:'32px', textAlign:'center'}}>Sign Up</H1text>
                        </div>
                        <div className='form_box' style={{ marginTop: '32px' }}>
                            <form onSubmit={getFormData}>
                                {/* <div className='mb-3'>
                                    <input type='text' placeholder='Enter Name' name='name' autoFocus value={userName} className="form-control" onChange={handleNameChange} required />
                                </div> */}
                                <div className='' style={{ marginBottom: '20px' }}>
                                    <input type='email' placeholder='Email' name='email' autoFocus value={email} className="form-control" onChange={handleEmailChange} required />
                                    {!validEmail && <p  className='text-danger pt-2 text-left' style={{fontSize:'12px'}}>Please enter a valid email address.</p> }
                                </div>
                                <div className=' relative' style={{ marginBottom: '20px' }}>
                                    <input type={showPassword ? 'text' : 'password'} placeholder='Password' name='password' autoFocus value={password} className="form-control" onChange={handlePasswordChange} required />
                                    <span className="passwordIcon cursor-pointer" onClick={() => setShowPassword(!showPassword)} >{showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</span>
                                    {error && <p  className='text-danger pt-2 text-left' style={{fontSize:'12px'}}>{error}</p>}
                                </div>

                                {/* <div className='mb-3 relative'>
                                    <input type='password' placeholder='Confirm Password' name='Confirmpassword' autoFocus value={confirmPassword} className="form-control" onChange={handleConfirmPasswordChange} required />
                                    {pwdError && <p className='text-danger pt-2 text-left' style={{fontSize:'12px'}}>{pwdError}</p>}
                                </div> */}
                                <div style={{ marginBottom: '20px' }}>
                                {/* <Ptext size='16' lineHeight='24' fontWeight='600' >Your purchases</Ptext> */}
                                    <Ptext size='14' lineHeight='20' fontWeight='500' color='#4579F5' onClick={() => navigate("/forgotpassword")} className='text-left text-decoration-underline mb-3 cursor-pointer'>Forgot password?</Ptext>
                                </div>
                                <button type='submit' className='btn btn-dark btn-lg submit w-100 cursor-pointer' style={{marginBottom: '32px'}} >Sign Up</button>
                                <div className='text-center'>
                                    <Ptext size='14' lineHeight='20' fontWeight='500' className='mb-0 fw-500' style={{ fontSize: '14px' }}>  By clicking “Sign up”, you agree  <br /> to the <span className='text-decoration-underline'>Terms of Service</span>   </Ptext>
                                </div>
                                <div className='text-center' style={{marginTop:'64px'}}>
                                    <Ptext size='14' lineHeight='20' fontWeight='500' className='mb-0 fw-500' style={{ fontSize: '14px' }}>Already have an account?</Ptext>
                                    <Ptext size='14' lineHeight='20' fontWeight='500' onClick={() => navigate("/signin")} className='text-center fw-600 cursor-pointer text-decoration-underline'  style={{marginBottom: '122px', fontSize: '14px', color: '#0d6efd' }}>Sign In</Ptext>
                                </div>

                            </form>
                        </div>


                    {/* </Col>
                </Row>
            </Container> */}

        </div>
        </Box>
    )
}

export default SignIn